import React from 'react';
import { Control} from 'react-redux-form';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { actions } from 'react-redux-form';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        searchTripsForm: state.searchTripsForm,
        addTripForm : state.addTripForm,
        addDeliveryForm : state.addDeliveryForm,
    }
  }
const mapDispatchToProps = dispatch => ({
    changeAndSubmit: (model, value) => {  dispatch(actions.change(model, value)); }, 
  });
class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: ''  };
    }
    componentDidMount(){
        var model = this.props.model.toString().replace(".", "");
        if(this.props.formName === "searchTripsForm")
        this.setState({ address  : this.props.searchTripsForm[model].split("--")[1]});
        else if(this.props.formName === "addTripForm")
        this.setState({ address  : this.props.addTripForm[model].split("--")[1]});
        else 
        this.setState({ address  : this.props.addDeliveryForm[model].split("--")[1]});
    }
    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = (address) => {
        geocodeByAddress(address)
            .then(results => {
                var address = results[0].address_components;
                for (var p = address.length - 1; p >= 0; p--) {
                    var result ='';
                    if (address[p].types.indexOf("locality") !== -1) {
                        var city = address[p].long_name;
                        var formattedAdress = results[0].formatted_address;
                        result = `${city}--${formattedAdress}`; 
                        this.setState({address :formattedAdress })
                        this.props.changeAndSubmit(`${this.props.formName}${this.props.model}`,`${result}`) 
                        this.props.onClickSuggestionItem();              
                    }
                }
            })
            .catch(error => console.error('Error', error));
    };

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div>
                        <Control.text style={{ backgroundColor: window.location.pathname === '/home' ? 'white': 'hidden'}}                     
                            {...getInputProps({
                                placeholder: this.props.placeholder,                              
                                model: this.props.model,
                                id: this.props.id,
                                name: this.props.name,
                                autoFocus:this.props.autoFocus , 
                                className:"form-control"
                                
                            })}                          
                        />
                        <i className="fa fa-map-marker localisationCity"></i>
                        <div className="autocomplete-dropdown-container">
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? { backgroundColor: 'rgb(237, 237, 237)', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                // inline style for demonstration purpose
                                return (

                                    <ListGroup flush>
                                        <ListGroupItem  {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })} tag="a">{suggestion.description}</ListGroupItem>
                                    </ListGroup>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LocationSearchInput);