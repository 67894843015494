import React, { useState } from 'react';
import { CardBody, CardTitle, CardText } from 'reactstrap';
import { Loading } from '../common/LoadingComponent';
import { Col, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../common/ConfirmActionComponent';


const RenderDescription = ({ description }) => {
    if (description)
        return (
            <React.Fragment>
                <hr />
                <CardText><strong>Description : </strong>  {description} </CardText>
            </React.Fragment>

        );
    else
        return (
            <div></div>
        )
}
function RenderTrip({ trip }) {
    const departure = trip.departure.split('--');

    const Dest1 = () => {
        const dest = trip.destination.split('--');
        if (dest[1]){
            return (
                <CardText ><i className="fa fa-arrow-down"></i> <strong>{dest[1]}</strong></CardText>
            )
        }else  
            return (
                <div></div>
            )
    }
    const Dest2 = () =>  {
        const dest = trip.destination.split('--');
        if (dest[2]){
            return (
                <CardText ><i className="fa fa-arrow-down"></i> <strong>{dest[2]}</strong></CardText>
            )
        }else  
            return (
                <div></div>
            )
    }
    const Dest3 = () => {
        const dest = trip.destination.split('--');
        if (dest[3]){
            return (
                <CardText ><i className="fa fa-arrow-down"></i> <strong>{dest[3]}</strong></CardText>
            )
        }else  
            return (
                <div></div>
            )
    }
    const Dest4 = () => {
        const dest = trip.destination.split('--');
        if (dest[4]){
            return (
                <CardText><i className="fa fa-map-marker"></i> <strong>{dest[0]}</strong> - {dest[4]}</CardText>
            )
        }else  
            return (
                <div></div>
            )
    }
    return (
        <div key={trip.id} className="mt-3">
            <div >
                <Col>
                    <Row>
                        <CardBody>
                            <CardTitle heading>
                                Le {new Intl.DateTimeFormat('fr-FR', { weekday: 'long', month: 'long', day: '2-digit' }).format(trip.dateDeparture)}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </CardTitle>
                            <CardText > <i className="fa fa-map-marker"></i> <strong>{departure[0]}</strong> - {departure[1]}</CardText>
                            <Dest1 destination= {trip.destination}/>
                            <Dest2 destination= {trip.destination}/>
                            <Dest3 destination= {trip.destination}/>
                            <Dest4 destination= {trip.destination}/>
                            <hr />
                            <CardText><strong>Colis à transporter : </strong>  {trip.objectAccepted} </CardText>
                            <CardText><strong>Moyen de transport  : </strong>  {trip.meansOfTransport}</CardText>
                            <CardText><strong>Prix : </strong>  {trip.price} €</CardText>
                            <RenderDescription description={trip.description} />
                        </CardBody>
                    </Row>
                </Col>
            </div>
        </div>
    )
}

const MyTripDetail = (props) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    function handleDeleteAnnouncement() {
        props.deleteTrip(props.trip.id);
    }
    if (props.isLoading) {
        return (
            <div className="container">
                <div className="row">
                    <Loading />
                </div>
            </div>
        );
    } else if (props.errMess) {
        return (
            <div className="container">
                <div className="row">
                    <h4>{props.errMess}</h4>
                </div>
            </div>
        );
    }
    else if (props.trip != null) {
        return (
            <div className="container">
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }} >
                        <h3 className="text-center mt-3">Mon trajet</h3>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <br />
                        <RenderTrip trip={props.trip} />
                        <Col className="text-center">

                            <Button color="secondary" variant="contained" onClick={setConfirmOpen}>
                                Annuler votre trajet
                                    </Button>
                            <ConfirmDialog
                                title="Supprimer Annonce ?"
                                open={confirmOpen}
                                setOpen={setConfirmOpen}
                                onConfirm={handleDeleteAnnouncement}
                            >
                                Vous êtes sur le point d'annuler votre trajet.
                                Celui-ci sera supprimé et les expéditeurs ne pourront plus transporter des colis avec vous.
                        </ConfirmDialog>
                        </Col>
                    </Col>
                </Row>
                <br /><br /><br />
            </div>
        );
    } else {
        return (<div></div>);
    }
}


export default MyTripDetail;


