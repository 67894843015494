import {actionType as notificationsActionType} from '../actions/actionType/notification.actionTypes';

const initialState =  {
    isLoadingNotifications:false,
    errMess : null,
    notifications : []
   } 

export const notifications = (state = initialState, action) => {
    switch (action.type) {

        case notificationsActionType.NOTIFICATION_REQUEST:
            return {...state,isLoadingNotifications : true, errMess:null, notifications:[] }

        case notificationsActionType.NOTIFICATION_SUCCESS:
            return {...state,isLoadingNotifications : false, errMess:null, notifications:action.payload }

        case notificationsActionType.NOTIFICATION_FAILURE:
            return {...state,isLoadingNotifications : false, errMess:action.payload, notifications:[]}
        
        case notificationsActionType.UPDATE_NOTIFICATION_SUCCESS:
                return state;    
        default:
          return state;
      }
};
