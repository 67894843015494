import {actionType as addDeliveryActionType} from '../actions/actionType/addDelivery.actionTypes';


const initialState =  {
    isAddingDelivery:false,
    errMess : null,
    responseAddDelivery : {}
   } 
export const addDelivery = (state = initialState, action) => {
    switch (action.type) {

        case addDeliveryActionType.ADDDELIVERY_REQUEST:
            return {...state,isAddingDelivery : true, errMess:null, responseAddDelivery:{}}

        case addDeliveryActionType.ADDDELIVERY_SUCCESS:
            return {...state,isAddingDelivery : false, errMess:null, responseAddDelivery:action.payload}

        case addDeliveryActionType.ADDDELIVERY_FAILURE:
            return {...state,isAddingDelivery : false, errMess:action.payload, responseAddDelivery:{}}

        default:
          return state;
      }
};