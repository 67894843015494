
import {actionType as userActionType} from '../actions/actionType/user.actionTypes';


let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? {
    isLogin:false,
    errMess : null,
    user : user
   } : {};
export const authentification = (state = initialState, action) => {
    switch (action.type) {

        case userActionType.LOGIN_REQUEST:
            return {...state,isLogin : true, errMess:null, user:{}}

        case userActionType.LOGIN_SUCCESS:
            return {...state,isLogin : false, errMess:null, user:action.payload}

        case userActionType.LOGIN_FAILURE:
            return {...state,isLogin : false, errMess:action.payload, user:{}}

        case userActionType.LOGOUT:
            return {...state,isLogin : false, errMess:null, user:{}}


         case userActionType.SEND_EMAIL_RESETPASSWORD:
            return state;  
        


        case userActionType.UPDATEUSER_SUCCESS:
            return {...state,isLogin : false, errMess:null, user:action.payload}
        case userActionType.UPDATEUSER_REQUEST:
            return {...state,isLogin : true, errMess:null, user:{}}  
        case userActionType.UPDATEUSER_FAILURE:
            return {...state,isLogin : false, errMess:action.payload, user:{}}



        case userActionType.UPDATE_PASSWORD:
            return {...state,isLogin : false, errMess:null, user:{}}   
             

        default:
          return state;
      }
};