import React, { useState } from 'react';
import { Card, CardBody, CardTitle, CardText, ListGroupItem, ListGroup, Collapse } from 'reactstrap';
import { Loading } from '../common/LoadingComponent';
import { Fade, Stagger } from 'react-animation-components';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const RenderDescription = ({ description }) => {
    if (description)
        return (
            <React.Fragment>
                <hr />
                <CardText style={{overflow: "hidden",
  textOverflow: "ellipsis",maxWidth: "330px"}} ><strong>Description : </strong>  {description} </CardText>
            </React.Fragment>

        );
    else
        return (
            <div></div>
        )
}

const RenderMeansOfTransport = ({ meansOfTransport }) => {
    if (meansOfTransport)
        return (
            <React.Fragment>
                <hr />
                <CardText><strong>Moyen de transport  : </strong>  {meansOfTransport}</CardText>
            </React.Fragment>

        );
    else
        return (
            <div></div>
        )
}

const RenderDate = ({ dateDeparture, dateDestination }) => {
    if (dateDestination)
        return (
            <React.Fragment>
                <CardTitle heading>
                    Départ &nbsp; : le {new Intl.DateTimeFormat('fr-FR', { weekday: 'short', month: 'long', day: '2-digit' }).format(dateDeparture)}
                                        <br/>
                    Arrivé &nbsp;&nbsp; : le {new Intl.DateTimeFormat('fr-FR', { weekday: 'short', month: 'long', day: '2-digit' }).format(dateDestination)}
                </CardTitle>
            </React.Fragment>

        );
    else
        return (
            <CardTitle heading>
                À livrer avant : le {new Intl.DateTimeFormat('fr-FR', { weekday: 'short', month: 'long', day: '2-digit' }).format(dateDeparture)}

            </CardTitle>
        )
}
function RenderAnnouncement({ announcement, user }) {
    const departure = announcement.departure.split('--');
 
    const Destination = () => {
        const dest = announcement.destination.split('--');
        if(dest.length == 2){
        
            if (dest[1] !== "" && dest[0] !== ""){
                return (
                    <CardText><i className="fa fa-map-marker"></i> <strong>{dest[0]}</strong> - {dest[1]}</CardText>
                )
            }else  
                return (
                    <div></div>
                )
        } else if (dest.length == 3){
            
            if (dest[0] !== "" && dest[1] !== "" && dest[2] !== ""){
                return (
                    <>
                    <CardText> <i className="fa fa-arrow-down"></i> <strong> {dest[1]}</strong></CardText>
                    <CardText> <i className="fa fa-map-marker"></i> <strong>{dest[0]}</strong> - {dest[2]}</CardText>
                    </>
                )
            }else  
                return (
                    <div></div>
            )
        }else if (dest.length == 4){
            
            if (dest[0] !== "" && dest[1] !== "" && dest[2] !== "" && dest[3] !== ""){
                return (
                    <>
                    <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[1]}</strong></CardText>
                    <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[2]}</strong> </CardText>
                    <CardText > <i className="fa fa-map-marker"></i> <strong>{dest[0]}</strong> - {dest[3]}</CardText>
                    </>
                )
            }else  
                return (
                    <div></div>
            )
        }else if (dest.length == 5){
            
            if (dest[0] !== "" && dest[1] == "" && dest[2] == "" && dest[3] == "" && dest[4] !== ""){
                return (
                    <>
                    <CardText > <i className="fa fa-map-marker"></i> <strong>{dest[0]}</strong> - {dest[4]}</CardText>
                    </>
                )
            }else if (dest[0] !== "" && dest[1] !== "" && dest[2] == "" && dest[3] == "" && dest[4] !== ""){
                return (
                    <>
                    <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[1]}</strong></CardText>
                    <CardText > <i className="fa fa-map-marker"></i> <strong>{dest[0]}</strong> - {dest[4]}</CardText>
                    </>
                )
            }else if (dest[0] !== "" && dest[1] !== "" && dest[2] !== "" && dest[3] == "" && dest[4] !== ""){
                return (
                    <>
                    <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[1]}</strong></CardText>
                    <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[2]}</strong> </CardText>
                    <CardText > <i className="fa fa-map-marker"></i> <strong>{dest[0]}</strong> - {dest[4]}</CardText>
                    </>
                )
            }
            else if (dest[0] !== "" && dest[1] !== "" && dest[2] !== "" && dest[3] !== "" && dest[4] !== ""){
                return (
                    <>
                    <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[1]}</strong></CardText>
                    <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[2]}</strong> </CardText>
                    <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[3]}</strong> </CardText>
                    <CardText > <i className="fa fa-map-marker"></i> <strong>{dest[0]}</strong> - {dest[4]}</CardText>
                    </>
                )
            }
            else  
                return (
                    <div></div>
                )
        }
    }

    return (
        <div key={announcement.id} className="mt-3">
            <div >
                <Col>
                    <Row>
                        <CardBody>
                            <RenderDate dateDeparture={announcement.dateDeparture} dateDestination={announcement.dateDestination} />
                            <CardText > <i className="fa fa-map-marker"></i> <strong>{departure[0]}</strong> - {departure[1]}</CardText>
                            <Destination destination= {announcement.destination}/>
                            <hr />
                            <CardText><strong>Colis à transporter : </strong>  {announcement.objectAccepted} </CardText>
                            <RenderMeansOfTransport meansOfTransport={announcement.meansOfTransport} />
                            <CardText><strong>Prix : </strong>  {announcement.price} €</CardText>
                            <RenderDescription description={announcement.description} />
                            <ListGroup flush>
                                <Link to={{
                                    pathname: `/userprofile/${user.id}`,
                                    state: { from: window.location.pathname }
                                }} className="inheritLinkColor">
                                    <ListGroupItem action>  <CardText>
                                        <img
                                            className="user-avatar rounded-circle mr-2"
                                            src="/assets/images/profil.svg"
                                            alt="User Avatar"
                                            height="33" width="33"
                                        />{" "}
                                        <span className="text-secondary"> <strong>{user.firstName} {user.lastName} </strong></span>
                                    </CardText>
                                    </ListGroupItem>
                                </Link>
                                <Link to={{
                                    pathname: `/messages/${announcement.id}${user.id}`,
                                    state: { from: window.location.pathname }
                                }} className="inheritLinkColor">
                                    <ListGroupItem className="text-secondary" action>  <CardText>
                                        <i className="fa fa-comments fa-lg"></i>
                                        <span className="text-secondary"><strong> Contacter {user.firstName} {user.lastName}</strong> </span>
                                    </CardText>
                                    </ListGroupItem>
                                </Link>
                            </ListGroup>
                        </CardBody>
                    </Row>
                </Col>
            </div>
        </div>
    )
}

const AnnouncementDetail = (props) => {

    if (props.isLoading) {
        return (
            <div className="container">
                <div className="row">
                    <Loading />
                </div>
            </div>
        );
    } else if (props.errMess) {
        return (
            <div className="container">
                <div className="row">
                    <h4>{props.errMess}</h4>
                </div>
            </div>
        );
    }
    else if (props.announcementWithUser != null) {
        return (
            <div className="container">
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }} >
        <h3 className="text-center mt-3">{props.announcementWithUser.announcement.dateDestination ? 'Trajet' : 'Demande de livraison'}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <RenderAnnouncement announcement={props.announcementWithUser.announcement} user={props.announcementWithUser.user} />
                    </Col>                    
                </Row>
                <br/><br/><br/><br/>
            </div>
        );
    } else {
        return (<div></div>);
    }
}


export default AnnouncementDetail;