import {actionType as myDeliveryActionType} from '../actions/actionType/myDeliveries.actionTypes';


const initialState =  {
    isLoadingMyDeliveries:false,
    errMess : null,
    myDeliveries : []
   } 
export const myDeliveries = (state = initialState, action) => {
    switch (action.type) {

        case myDeliveryActionType.MYDELIVERIES_REQUEST:
            return {...state,isLoadingMyDeliveries : true, errMess:null, myDeliveries:[]}

        case myDeliveryActionType.MYDELIVERIES_SUCCESS:
            return {...state,isLoadingMyDeliveries : false, errMess:null, myDeliveries:action.payload}

        case myDeliveryActionType.MYDELIVERIES_FAILURE:
            return {...state,isLoadingMyDeliveries : false, errMess:action.payload, myDeliveries:[]}

        case myDeliveryActionType.ADD_DELIVERY:
                return {...state,isLoadingMyDeliveries : false, errMess:null, myDeliveries:state.myDeliveries.concat(action.payload)}    

        default:
          return state;
      }
};

//ARRAY.concat return an other array ===>>  immutable