import React, { useEffect } from 'react';
import { Button, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FadeTransform } from 'react-animation-components';



function HowItWorks(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Jumbotron>
                <div className="container">
                    <div className="row row-header">
                        <div className="col-12 col-sm-6">
                            <h1>Comment ça marche ?</h1>
                        </div>

                    </div>
                </div>
            </Jumbotron>

            <div className="container row row-content">
                <div className="col-12">
                    <h3>Voyageur</h3>
                    <br />
                </div>
                <div className="col-12 col-sm-9 offset-sm-1">
                    <div className="col-12">
                        <h4>1. Publiez votre trajet</h4>
                             Renseignez  où vous allez, quand vous partez et le volume du colis que vous pouvez transporter.
                    </div>
                    <br/>
                    <div className="col-12">
                        <h4> Ou cherchez une demande livraison</h4>
                             Recherchez les demandes de livraison mises en ligne par expéditeurs  
                    </div>
                    <br/><br/>
                    <div className="col-12">
                        <h4>2. Les expéditeurs vous contactent en messages privés </h4>
                        Un expéditeur vous contacte pour mettre le point sur  tous les details du livraison.<br/>
                        Prix de livraiosn, date de livraison, lieux de rencontre ...
                    </div>

                    <br/><br/>
                    <div className="col-12">
                        <h4>3. Recevez votre paiement </h4>
                        Après le trajet, un destinataire recupère le colis et vous paye en main propre. 
                    </div>
                    <br/><br/>
                </div>
                
                <div className="col-12">
                    <h3>Expéditeur</h3>
                    <br />
                </div>
                <div className="col-12 col-sm-9 offset-sm-1">
                    <div className="col-12">
                        <h4>1. Publiez votre annonce</h4>
                        Renseignez simplement votre destination, votre  départ et  une description en details de votre colis.
                    </div>
                    <br/>
                    <div className="col-12">
                        <h4>Ou cherchez un trajet</h4>
                             Recherchez les trajets mises en ligne par voyageurs  
                    </div>
                    <br/><br/>
                    <div className="col-12">
                        <h4>2. Les voyageurs vous contactent en messages privés </h4>
                        Un voyageur vous contacte pour mettre le point sur  tous les details du trajet.<br/>
                        Type de colis, prix de livraiosn, date de livraison, lieux de rencontre ...
                    </div>

                    <br/><br/>
                    <div className="col-12">
                        <h4>3. Préparez votre colis </h4>
                        Préparez  et bien emballer votre colis. 
                    </div>
                    <br/><br/>
                    <div className="col-12">
                        <h4>4. Confirmez la livraison </h4>
                        La réception a été validée? confirmez la réception avec votre destinataire. 
                    </div>

              </div>
            </div>
        </div>
      
    );
}

export default HowItWorks;