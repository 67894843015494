import { actionType as messagesActionType } from './actionType/message.actionTypes';
import { baseUrl } from '../shared/baseUrl';
import { authHeader } from '../helpers/auth-header';
import { currentUser } from '../helpers/currentUser';




export const fetchMessages = () => (dispatch) => {
    if(localStorage.getItem('user')){
    dispatch(messagesRequest());
    return fetch(`${baseUrl}/service/messages?iduser=${currentUser().id}`, {
        method: 'GET',
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json' },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error('Error ' + response.status + ':' + response.error);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(response => dispatch(messagesSuccess(response)))
        .catch(error =>  dispatch(messagesFailure(error.message)))

     }

}


export const messagesRequest = () => ({
    type: messagesActionType.MESSAGES_REQUEST
});

/* a action*/
export const messagesFailure = (errmess) => ({
    type: messagesActionType.MESSAGES_FAILURE,
    payload: errmess
});

/* a action*/
export const messagesSuccess = (messages) => ({
    type: messagesActionType.MESSAGES_SUCCESS,
    payload: messages
});


export const sendMessage = (newMessage) => (dispatch) => {
    return fetch(baseUrl + '/service/sendmessage', {
        method: 'POST',
        body: JSON.stringify(newMessage),
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json' },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(response => {
                dispatch(sendMsg(response));              
        })
        .catch(error => {
        })

}
export const sendMsg = (message) => ({

    type: messagesActionType.SEND_MESSAGE,
    payload: message
});


const messageOrderByannouncement = (message) => {
    const results= message.reduce((result, currentValue) => {              
         (result[currentValue['announcement']['id']] = result[currentValue['announcement']['id']] || [] ).push(
           currentValue
         );
         return result;
       }, {});
 
       return Object.values(results);
 };




