import { actionType as addtripActionType } from './actionType/addTrip.actionTypes';
import { error as errorAlert, success as successAlert } from './alert.actionCreator';
import {  postTrip } from './myTrips.actionCreator';
import { baseUrl } from '../shared/baseUrl';
import { authHeader } from '../helpers/auth-header';
import { currentUser } from '../helpers/currentUser';
import { history } from '../helpers/history';




export const addTrip = (trip) => (dispatch) => {
    
    const destinationSplice = (step, dest) => {
        dest.splice(dest.length-1, 0, step);
        return dest;
    }
    const buildDestination = (destination, destination1, destination2, destination3) => {
        const dest = trip.destination.split('--');
        const dest1 = trip.destination1.split('--');
        const dest2 = trip.destination2.split('--');
        const dest3 = trip.destination3.split('--')
        if(destination1 !== null){
            destination = destinationSplice(dest1[0], dest);
            if(destination2 !== null){
                destination = destinationSplice(dest2[0], dest);
                if(destination3 !== null){
                destination = destinationSplice(dest3[0], dest);
                }
            }	
        }
        return destination.join('--');
    } 
 
    const newtrip = {
        departure:trip.departure,
        destination : buildDestination(trip.destination, trip.destination1, trip.destination2, trip.destination3),
        dateDeparture: Date.parse(trip.dateDeparture),
        dateDestination : Date.parse(trip.dateDestination),
        meansOfTransport:trip.meansOfTransport,
        objectAccepted : trip.objectAccepted,
        price : trip.price,
        description : trip.description,
        idUser : currentUser().id
    }
 
    dispatch(addTripRequest());
    return fetch(baseUrl + '/service/addannouncement', {
        method: 'POST',
        body: JSON.stringify(newtrip),
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json' },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(response => {
                dispatch(addTripSuccess(response));
                dispatch(postTrip(response));
                history.push('/mytrips')
                dispatch(successAlert("Votre annonce est en ligne des expéditeur peuvent  vous contacter pour transporter des Colis sur votre trajet"));
        })
        .catch(error => {
            dispatch(addTripFailure(error));
            dispatch(errorAlert(error.toString()));
        })

}


export const addTripRequest = () => ({
    type: addtripActionType.ADDTRIP_REQUEST
});

/* a action*/
export const addTripFailure = (errmess) => ({
    type: addtripActionType.ADDTRIP_FAILURE,
    payload: errmess
});

/* a action*/
export const addTripSuccess = (tripInerted) => ({
    type: addtripActionType.ADDTRIP_SUCCESS,
    payload: tripInerted
});
