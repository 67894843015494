import React, { Component } from 'react';
import { ListGroupItem, ListGroup,Badge ,Col, Row  } from 'reactstrap';
import {  Stagger } from 'react-animation-components';
import { Link } from 'react-router-dom';
import { Loading } from '../common/LoadingComponent';
import { currentUser } from '../../redux/helpers/currentUser';




class Messages extends Component {

    constructor(props) {
        super(props);
    }

    handleClick(item)  {
        this.props.updateNotification(item.announcement.id,currentUser().id,item.user.id);
     }
    

    render() {

        const RenderNotification = ({iduserSender,idannouncement}) => {  
            if (this.props.isLoading) {
                return (
                    <div></div>
                );
            }
            else if (Array.isArray(this.props.notifications) && this.props.notifications.length) {
                const notificationFilter = this.props.notifications.filter((notification) =>
                notification.idAnnouncement === idannouncement &&
                notification.idUser === currentUser().id &&
                notification.idUserSender === iduserSender  )[0];
                if(notificationFilter)
                return (
                    <Badge color="danger" pill>{notificationFilter.totalMessages}</Badge>
                );
                else
                return (
                    <div></div>
                )
            }
            else
            return (
                <div></div>
            )
        }
        const RenderConversationItem = ({ conversation }) => {
            const departure = conversation.announcement.departure.split('--');
            const destination = conversation.announcement.destination.split('--');

            return (
                <Link to={{
                    pathname: `/messages/${conversation.announcement.id}${conversation.user.id}`,
                    state: { from: window.location.pathname }
                }}  className="inheritLinkColor">
                    <div key={conversation.announcement.id} className="blog-comments__item d-flex p-3">
                        {/* Avatar */}
                        <div className="blog-comments__avatar mr-3">
                            <img
                                className="user-avatar rounded-circle mr-2"
                                src="/assets/images/profil.svg"
                                alt="User Avatar"
                                height="40" width="40"
                            />{" "}
                        </div>

                        <div>
                            {/* Content :: Title */}
                            <div>
                                <h5>
                                    {conversation.user.firstName} {conversation.user.lastName}  {" "}
                                    <RenderNotification 
                                      iduserSender={conversation.user.id} 
                                      idannouncement={conversation.announcement.id}                  />
                                </h5>{" "}
                            </div>

                            {/* Content :: Body */}
                            <h5 className="text-muted">
                                {new Intl.DateTimeFormat('fr-FR', { weekday: 'short', month: 'short', day: '2-digit' }).format(conversation.announcement.dateDeparture)}
                                {" "} 
                            </h5>

                            {/* Content :: Actions */}
                            {/* Content :: Actions */}
                            <h6 className="text-muted">
                            {departure[0]} ---<i className="fa fa-angle-right fa-lg"></i> {destination[0]}
                         </h6>
                        </div>
                    </div>
                </Link>
            );
        }
       

        const conversations = this.props.conversations.map((item) => {
            return (
                    <ListGroup>
                        <ListGroupItem onClick={() => this.handleClick(item)} className="p-0">
                            <RenderConversationItem conversation={item} />
                        </ListGroupItem>
                    </ListGroup>
            );
        });

        if (this.props.isLoading) {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <Loading />
                    </Col>
                </Row>
            );
        }
        else if (this.props.errMess) {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <h4>{this.props.myTrips.errMess}</h4>
                    </Col>
                </Row>
            );
        }
        else if (Array.isArray(this.props.conversations) && this.props.conversations.length)
            return (
                <div class="container">
                    <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }} >
                            <h3 className="text-center mt-3">Mes Messages</h3>
                            <br />
                            <Stagger in>
                                {conversations}
                                <br/><br/><br/>
                            </Stagger>
                        </Col>
                    </Row>
                </div>
            )
        else
            return (
                <div class="container">
                <Row>
                    <Col className="text-center mt-5" sm="12" md={{ size: 6, offset: 3 }} >
                        <br /><br />
                        <h2>vous n'avez aucun message !! </h2>
                        <br />
                    </Col>
                </Row>
                </div>


            );
    }
}


export default Messages;