import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { authenticateWithFacebook, authenticateWithGoogle,updateTokenDevise } from '../../redux/actions/user.actionCreator';
import { connect } from 'react-redux';


const mapDispatchToProps = dispatch => ({
    authenticateWithFacebook: (name, email,tokenDevice) => dispatch(authenticateWithFacebook(name, email,tokenDevice)),
    authenticateWithGoogle: (name, email,tokenDevice) => dispatch(authenticateWithGoogle(name, email,tokenDevice)),
    updateTokenDevise : (tokenDevise) => dispatch(updateTokenDevise(tokenDevise)),
});
class SocialLogin extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        // When the component is mounted, add your DOM listener to the "nv" elem.
        // (The "nv" elem is assigned in the render function.)
        document.addEventListener("message", this.handleNativeMessage);
        window.addEventListener("message", this.handleNativeMessage);//for ios
    }
    componentWillUnmount() {
        document.removeEventListener('message', this.handleNativeMessage);
        window.removeEventListener("message", this.handleNativeMessage);//for ios
    }
    
    facebookLoginClicked() {
        if (window.ReactNativeWebView != undefined) {
            window.ReactNativeWebView.postMessage('facebookLoginClicked')
        } else {
            console.log("web Login Facebook")
        }
    }

    googleLoginClicked() {
        if (window.ReactNativeWebView != undefined) {
            window.ReactNativeWebView.postMessage('googleLoginClicked')
        } else {
            console.log("web Login google")
        }
    }

    appleLoginClicked() {
        if (window.ReactNativeWebView != undefined) {
            window.ReactNativeWebView.postMessage('appleLoginClicked')
        } else {
            console.log("web Login apple")
        }
    }

    responseFacebook(response) {
        console.log(response);
        if (response.email && response.name) {
            this.props.authenticateWithFacebook(response.name, response.email);
        }
        else
            this.props.authenticateWithFacebook(response.name, response.userID);
    }

    responsApple = (response) => {
        console.log(response);
        const fullName = response.user.firstName + ' ' + response.user.lastName;
        this.props.authenticateWithGoogle(fullName, response.user.email);
    }

    responseGoogle = (response) => {
        const fullName = response.profileObj.name;
        this.props.authenticateWithGoogle(fullName, response.profileObj.email);
    }

    handleNativeMessage = (e) => {
        if(e.data){
        let event = JSON.parse(e.data)
        if (event.type == 'facebookLoginNative') {
            if (event.data.email)//facebook contain email
                this.props.authenticateWithFacebook(event.data.name, event.data.email,this.props.TokenDeviseRegister);
            else
                this.props.authenticateWithFacebook(event.data.name, event.data.id,this.props.TokenDeviseRegister);
        }
        if (event.type == 'googleLoginNative') {
            if (event.data.email)//facebook contain email
                this.props.authenticateWithGoogle(event.data.name, event.data.email,this.props.TokenDeviseRegister);
            else
                this.props.authenticateWithGoogle(event.data.name, event.data.id,this.props.TokenDeviseRegister);
        }
        if (event.type == 'appleLoginNative') {
            if (event.data.fullName && event.data.email) {//facebook contain email
                const fullName = event.data.fullName.givenName + ' ' + event.data.fullName.familyName;
                this.props.authenticateWithGoogle(fullName, event.data.user,this.props.TokenDeviseRegister);
            } else
                this.props.authenticateWithGoogle("aa bb", event.data.user,this.props.TokenDeviseRegister);

        }
        // if (event.type == 'androiApp') { 
        //     this.setState({
        //         isIosPlatform: false
        //       },() => { this.forceUpdate() })        
                
        // }
    }

    }

    render() {
        if (window.ReactNativeWebView != undefined) {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 9, offset: 0 }}>
                        <Button onClick={this.facebookLoginClicked} className="btn-facebook"><strong> <span className="fa fa-facebook-square fa-lg"></span> Connexion avec facebook</strong></Button>
                        <br />  <br />
                        <Button onClick={this.googleLoginClicked} className="btn-google"><strong> <span className="fa fa-google-plus-square fa-lg"></span> Connexion avec google&nbsp;&nbsp; </strong></Button>
                        <br />  <br />
                        { this.props.isNotAndroidPlatform ? <div> <Button onClick={this.appleLoginClicked} style={{
                            backgroundColor: "black"
                        }} className="btn-apple"> <strong> <span className="fa fa-apple fa-lg"></span> Connexion avec apple&nbsp;&nbsp;&nbsp;&nbsp; </strong> </Button> <br /> <br /> </div> : null
                        }
                    </Col>
                </Row>

            );
        } else {
            return (
                <React.Fragment className="mt-5">
                    <FacebookLogin
                        appId="3510498158961039"
                        autoLoad={false}
                        fields="name,email"
                        callback={this.responseFacebook.bind(this)}
                        textButton="  Connexion avec facebook"
                        language="fr-EU"
                        icon="fa-facebook-square fa-lg"
                        cssClass="FacebookClass"
                        redirectUri="https://eudeliv.com/login"
                    />
                    <br /> <br />
                    <GoogleLogin
                        clientId="816345816661-t0l1hi5g12rkasa14h9ja1sbij1faats.apps.googleusercontent.com"
                        buttonText="  Connexion avec Google"
                        render={renderProps => (
                            <Button onClick={renderProps.onClick} className="btn-google" style={{
                                color: "white",
                                fontSize: "large",
                                fontStyle: "oblique",
                                lineHeight: "15px",
                                borderWidth: "0px",
                                borderRadius: "25px",
                                padding: "13px"
                            }} >
                                <span className="fa fa-google-plus-square fa-lg">&nbsp;</span> Connexion avec google&nbsp;
                            </Button>
                        )}
                        onSuccess={this.responseGoogle.bind(this)}
                    />
                    <br /><br />
                    {/* <AppleSignin

    authOptions={{
      
     redirectURI:"https://eudeliv.com/home",
      clientId: 'com.eudeliv.app',    
      scope: 'email name',
      usePopup:true,
      nonce : "nonce"
    }} // REQUIRED
    onError={(error) => console.error(error)} // default = undefined
    onSuccess={this.responsApple.bind(this)}
    
    render={renderProps => (
        <Button onClick={renderProps.onClick} className="btn-apple" style={{
            color: "white",
            fontSize: "large",
            fontStyle: "oblique",
            lineHeight: "15px",
            borderWidth: "0px",
            borderRadius: "25px",
            padding: "13px",
            backgroundColor:"black"
        }} >
            <span className="fa fa-apple fa-lg">&nbsp;</span> Connexion avec apple&nbsp; 
            </Button>
)}
  /> */}
                </React.Fragment>

            )
        }

    }
}

export default connect(null, mapDispatchToProps)(SocialLogin);