import React from 'react';
import {
     Badge

} from 'reactstrap';



  const NotificationMessage = (props) => {
    if (props.isLoading) {
        return (
            <React.Fragment></React.Fragment>
        );
    }
    else if (Array.isArray(props.notifications) && props.notifications.length) {
        var sumNotifications = 0;
        for (var i = 0, n = props.notifications.length; i < n; i++) {
            sumNotifications += props.notifications[i].totalMessages;
        }
        return (
            <Badge color="danger" pill>{sumNotifications}</Badge>
        );
    }
    else
        return (
            <React.Fragment></React.Fragment>
        )
   
   
}
export default NotificationMessage;