import React, { Component } from 'react';
import { Button, Card, CardBody, CardTitle, CardText, Col, Row } from 'reactstrap';
import { Loading } from '../common/LoadingComponent';
import { Stagger } from 'react-animation-components';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';




class Announcements extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0
        };

    }
    
     handleChange = (event, newValue) => {
         console.log(newValue)
        this.setState({
            value: newValue
        })
      };
    
       handleChangeIndex = (index) => {
        this.setState({
            value: index
        })
      };




    render() {
        

        var styles = {
            AppBar: {
                background: 'transparent',
                boxShadow: 'none'
            },
            default_tab:{
              fontSize: '18px',
              color: '#38b6ff',
              fontWeight: 550,
            },
            active_tab:{
              color: "#283b82",
              outline:'none',
            }
          }

          styles.tab = []
  styles.tab[0] = styles.default_tab;
  styles.tab[1] = styles.default_tab;
  styles.tab[2] = styles.default_tab;
 styles.tab[this.state.value] = Object.assign({},styles.tab[this.state.value], styles.active_tab)
        function TabPanel(props) {
            const { children, value, index, ...other } = props;

            return (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`full-width-tabpanel-${index}`}
                    aria-labelledby={`full-width-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box p={1}>
                            <span>{children}</span>
                        </Box>
                    )}
                </div>
            );
        }

        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };

        function a11yProps(index) {
            return {
                id: `full-width-tab-${index}`,
                'aria-controls': `full-width-tabpanel-${index}`,
            };
        }



        const renderMeanTrasport = (meanOfTransport) => {
            switch (meanOfTransport) {
                case 'Avion':
                    return <React.Fragment>  <span className="fa fa-plane fa-lg"></span></React.Fragment>;
                case 'Voiture':
                    return <React.Fragment>  <span className="fa fa-car fa-lg"></span></React.Fragment>;
                case 'Train':
                    return <React.Fragment> <span className="fa fa-train fa-lg"></span></React.Fragment>;
                case 'Bus':
                    return <React.Fragment>  <span className="fa fa-bus fa-lg" ></span></React.Fragment>;
                default:
                    return <React.Fragment>  <span style={{color : "#00AFF5"}} className="fa fa-cube fa-lg" ></span></React.Fragment>;
            }
        }

        const RenderAnnouncementItem = ({ announcement, user }) => {
            const departure = announcement.departure.split('--');
            const Destination = () => {
                const dest = announcement.destination.split('--');
                if(dest.length == 2){
        
                    if (dest[1] && dest[0]){
                        return (
                            <CardText><h5> <i className="fa fa-map-marker"></i> <strong> {dest[1]}</strong></h5></CardText>
                        )
                    }else  
                        return (
                            <div></div>
                        )
                } else if (dest.length == 3){
                    
                    if (dest[1] && dest[2]){
                        return (
                            <>
                            <CardText> <i className="fa fa-arrow-down"></i> <strong> {dest[1]}</strong></CardText>
                            <CardText> <h5><i className="fa fa-map-marker"></i> <strong> {dest[2]}</strong></h5> </CardText>
                            </>
                        )
                    }else  
                        return (
                            <div></div>
                    )
                }else if (dest.length == 4){
                    
                    if (dest[0] && dest[1] && dest[2] && dest[3]){
                        return (
                            <>
                            <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[1]}</strong></CardText>
                            <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[2]}</strong> </CardText>
                            <CardText > <h5><i className="fa fa-map-marker"></i> <strong> {dest[3]}</strong> </h5></CardText>
                            </>
                        )
                    }else  
                        return (
                            <div></div>
                    )
                }else if (dest.length == 5){
                    
                    if (dest[0] !== "" && dest[1] == "" && dest[2] == "" && dest[3] == "" && dest[4] !== ""){
                        return (
                            <>
                            <CardText > <h5><i className="fa fa-map-marker"></i> <strong>{dest[4]}</strong></h5></CardText>
                            </>
                        )
                    }else if (dest[0] !== "" && dest[1] !== "" && dest[2] == "" && dest[3] == "" && dest[4] !== ""){
                        return (
                            <>
                            <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[1]}</strong></CardText>
                            <CardText > <h5><i className="fa fa-map-marker"></i> <strong>{dest[4]}</strong></h5></CardText>
                            </>
                        )
                    }else if (dest[0] !== "" && dest[1] !== "" && dest[2] !== "" && dest[3] == "" && dest[4] !== ""){
                        return (
                            <>
                            <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[1]}</strong></CardText>
                            <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[2]}</strong> </CardText>
                            <CardText > <h5><i className="fa fa-map-marker"></i> <strong>{dest[4]}</strong></h5></CardText>
                            </>
                        )
                    }
                    else if (dest[0] !== "" && dest[1] !== "" && dest[2] !== "" && dest[3] !== "" && dest[4] !== ""){
                        return (
                            <>
                            <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[1]}</strong></CardText>
                            <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[2]}</strong> </CardText>
                            <CardText > <i className="fa fa-arrow-down"></i> <strong> {dest[3]}</strong> </CardText>
                            <CardText > <h5><i className="fa fa-map-marker"></i> <strong>{dest[4]}</strong></h5></CardText>
                            </>
                        )
                    }
                    else  
                        return (
                            <div></div>
                        )
                }
        }

            return (
                <div key={announcement.id} className="mt-2">
                    <Card >
                        <Link to={{
                            pathname: `/announcements/${announcement.id}`,
                            state: { from: window.location.pathname }
                        }} className="inheritLinkColor">
                            <Row>
                                <Col xs="8">
                                    <CardBody>
                                        <CardTitle heading>
                                            {new Intl.DateTimeFormat('fr-FR', { weekday: 'short', month: 'short', day: '2-digit' }).format(announcement.dateDeparture)}
                                        &nbsp;&nbsp;
                                        {renderMeanTrasport(announcement.meansOfTransport)}
                                        </CardTitle>
                                        <CardText > <h5><i className="fa fa-map-marker"></i><strong> {departure[1]}</strong></h5></CardText>
                                        <Destination destination= {announcement.destination}/>
                                        {/* <CardText > <h5> <img
                                            className="user-avatar rounded-circle mr-2"
                                            src="/assets/images/profil.svg"
                                            alt="User Avatar"
                                            height="33" width="33"
                                        />{" "}
                                            <span className="text-dark">{user.firstName} {user.lastName} </span></h5></CardText> */}
                                    </CardBody>
                                </Col>
                                <Col xs="4">
                                    <CardBody>
                                        <h5 className="text-right">
                                            {announcement.price} €
                                    </h5>
                                    </CardBody>
                                </Col>
                            </Row>
                            
                             <h5> &nbsp;&nbsp; <img
                                            className="user-avatar rounded-circle mr-2"
                                            src="/assets/images/profil.svg"
                                            alt="User Avatar"
                                            height="33" width="33"
                                        />{" "}
                                            <span className="text-dark">{user.firstName} {user.lastName} </span></h5>
                                            
                        </Link>
                    </Card>
                </div>
            );
        }


        const announcements = this.props.announcementsWithUser.responseSearchAnnouncements.map((announcementsWithUser) => {
            return (
                    <RenderAnnouncementItem announcement={announcementsWithUser.announcement} user={announcementsWithUser.user} />
            );
        });

        const offres = this.props.announcementsWithUser.responseSearchAnnouncements
        .filter(announcementsWithUser => announcementsWithUser.announcement.dateDestination != null)
        .map((announcementsWithUser) => {
            return (
                    <RenderAnnouncementItem announcement={announcementsWithUser.announcement} user={announcementsWithUser.user} />
            );
        });

        const demandes = this.props.announcementsWithUser.responseSearchAnnouncements
        .filter(announcementsWithUser => announcementsWithUser.announcement.dateDestination == null)
        .map((announcementsWithUser) => {
            return (
                    <RenderAnnouncementItem announcement={announcementsWithUser.announcement} user={announcementsWithUser.user} />
            );
        });
            

        if (this.props.announcementsWithUser.isSearchAnnouncements) {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <Loading />
                    </Col>
                </Row>
            );
        }
        else if (this.props.announcementsWithUser.errMess) {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <h4>{this.props.announcementsWithUser.errMess}</h4>
                    </Col>
                </Row>
            );
        }
        else if (Array.isArray(this.props.announcementsWithUser.responseSearchAnnouncements) && this.props.announcementsWithUser.responseSearchAnnouncements.length)
            return (
                <div className="container">
                    <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }} >
                            <br/>
                            <Row className="text-center">
                        <Col sm="12" md={{ size: 6, offset: 3 }} >
                        <Link to="/searchannouncements">
                                <Button className="text-center" color="primary" size="lg">Nouvelle recherche </Button>
                            </Link>
                            </Col></Row>
                           
                            <Stagger in>
                                <AppBar position="static" color="white" style={styles.AppBar}>
                                    <Tabs  
                                        value={this.state.value}
                                        onChange={this.handleChange.bind(this)}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <Tab label="Tout" style={styles.tab[0]} icon={<span className="fa fa-lg"> {announcements.length}</span>}  {...a11yProps(0)} />
                                        <Tab label="Demande" style={styles.tab[1]} icon={<span className="fa fa-cube fa-lg"> {demandes.length}</span>} {...a11yProps(1)} />
                                        <Tab label="Trajet"  style={styles.tab[2]} icon={<span className="fa fa-road fa-lg"> {offres.length}</span>} {...a11yProps(2)} />
                                    </Tabs>
                                </AppBar>
                            
                                    <TabPanel value={this.state.value} index={0}>
                                    {announcements }
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={1}>
                                        {demandes.length>0 ? demandes : <h4 className="text-center"><strong>Pas demandes disponible !!</strong></h4> }
                                    </TabPanel>
                                    <TabPanel value={this.state.value} index={2}>
                                    {offres.length>0 ? offres : <h4 className="text-center"><strong>Pas d'offre disponible !!</strong></h4> }
                                    </TabPanel>
                                <br /><br /><br /><br />
                            </Stagger>
                        </Col>
                    </Row>
                </div>
            );
        else
            return (

                <Col>
                    <Col className="text-center mt-5" sm="12" md={{ size: 6, offset: 3 }} >
                        <br /><br />
                        <h2>Il n'y a pas encore de trajet ou livraison disponible entre ces villes !!</h2>
                        <br />
                        <Col>
                            <Link to="/adddelivery">
                                <Button color="primary" size="lg">Envoyer un colis </Button>
                            </Link>
                            <br /><br />
                            <Link to="/addtrip">
                                <Button color="primary" size="lg">Transporter un colis </Button>
                            </Link>
                            <br /><br />
                            <Link to="/searchannouncements">
                                <Button color="primary" size="lg">Rechercher à nouveau </Button>
                            </Link>
                            <br /><br /><br /><br />
                        </Col>
                    </Col>
                </Col>


            );
    }
}


export default Announcements;