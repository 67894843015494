import {actionType as myTripActionType} from '../actions/actionType/myTrips.actionTypes';


const initialState =  {
    isLoadingTrips:false,
    errMess : null,
    myTrips : []
   } 
export const myTrips = (state = initialState, action) => {
    switch (action.type) {

        case myTripActionType.MYTRIPS_REQUEST:
            return {...state,isLoadingTrips : true, errMess:null, myTrips:[]}

        case myTripActionType.MYTRIPS_SUCCESS:
            return {...state,isLoadingTrips : false, errMess:null, myTrips:action.payload}

        case myTripActionType.MYTRIPS_FAILURE:
            return {...state,isLoadingTrips : false, errMess:action.payload, myTrips:[]}

        case myTripActionType.ADD_TRIP:
                return {...state,isLoadingTrips : false, errMess:null, myTrips:state.myTrips.concat(action.payload)}    

        default:
          return state;
      }
};

//ARRAY.concat return an other array ===>>  immutable