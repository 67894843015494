import React, { Component } from 'react';
import {
    Button, Navbar, NavbarBrand, Nav, NavItem,
    UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    NavLink
} from 'reactstrap';
import NotificationMessage from './common/NotificationMessageComponent'
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hideNav: false,
            isNavOpen: false,
            isDropdownOpen: false,
            user: this.props.user,
        };
        this.toogleNav = this.toogleNav.bind(this);
        this.handleLogout = this.handleLogout.bind(this);

    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        let currentHideNav = (window.innerWidth <= 760);
        if (currentHideNav !== this.state.hideNav) {
            this.setState({ hideNav: currentHideNav });
        }
    }

    toogleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    handleLogout() {
        this.props.logout();
    }

    render() {

        if (window.location.pathname.split('/')[1] === 'messages' && window.location.pathname.split('/')[2] !== undefined)
            return (<></>);
        const RenderLoginNavbar = () => {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                if (!this.state.hideNav)
                    return (
                        <NavItem  >
                            <UncontrolledButtonDropdown>
                                <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
                                    <img
                                        className="user-avatar rounded-circle mr-2"
                                        src="/assets/images/profil.svg"
                                        alt="User Avatar"
                                        height="33" width="33"
                                    /><sup><NotificationMessage isLoading={this.props.isLoading} notifications={this.props.notifications}/></sup>
                                    <span className="text-dark"><strong > {user.user.firstName}  {user.user.lastName}  </strong></span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <Link to="/profil" className="inheritLinkColor">
                                        <DropdownItem>&nbsp;<span className="fa fa-user fa-lg"></span> &nbsp;&nbsp; Profil</DropdownItem>
                                    </Link>
                                    <DropdownItem divider />
                                    <Link to="/mytrips" className="inheritLinkColor">
                                        <DropdownItem>
                                            <span class="fa fa-road fa-lg"></span> &nbsp;&nbsp; Mes trajets
                                            </DropdownItem>
                                    </Link>

                                    <Link to="/mydeliveries" className="inheritLinkColor">
                                        <DropdownItem>
                                            <span class="fa fa-envelope fa-lg"></span> &nbsp;&nbsp; Mes envois
                                            </DropdownItem>
                                    </Link>

                                    <DropdownItem divider />
                                    <Link to="/messages" className="inheritLinkColor badgeNotificationRelative">
                                        <DropdownItem>
                                            <span class="fa fa-comments fa-lg"></span> &nbsp;&nbsp; Messages{" "}{" "}
                                            <sup><NotificationMessage isLoading={this.props.isLoading} notifications={this.props.notifications}/></sup>
                                        </DropdownItem>
                                    </Link>
                                    <DropdownItem onClick={this.handleLogout}><span class="fa fa-sign-out fa-lg"></span> &nbsp;&nbsp; Déconnexion</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </NavItem>
                    )
                else return (
                    <>
                        <DropdownItem divider />
                        <a >
                            <img className="user-avatar rounded-circle mr-2"
                                src="/assets/images/profil.svg"
                                alt="User Avatar"
                                height="30" width="30"
                            />{" "}
                            <span className="text-dark"><strong > {user.user.firstName}  {user.user.lastName} </strong></span>
                        </a>
                        <DropdownItem divider />
                        <a >
                            <Link className="nav-link text-primary" to="/profil" >
                                <span className="fa fa-user fa-lg"></span><strong className="text-primary"> Profil </strong>
                            </Link>
                        </a>
                        <a >
                            <Link className="nav-link text-primary" to="/mytrips" >
                                <span className="fa fa-road fa-lg"></span><strong className="text-primary"> Mes trajets </strong>
                            </Link>
                        </a>
                        <a >
                            <Link className="nav-link text-primary" to="/mydeliveries" >
                                <span className="fa fa-envelope fa-lg"></span><strong className="text-primary"> Mes envois </strong>
                            </Link>
                        </a>
                        <a >
                            <Link className="nav-link text-primary" to="/messages" >
                                <span className="fa fa-comments fa-lg"></span> <strong className="text-primary"> Mes messages </strong>
                                <NotificationMessage isLoading={this.props.isLoading} notifications={this.props.notifications}/>
                            </Link>
                        </a>
                        <a className="menu-item" onClick={this.handleLogout}>
                            <Link className="nav-link text-primary" to="/login" >
                                <span className="fa fa-sign-out fa-lg"></span><strong className="text-primary"> Déconnexion </strong>
                            </Link>
                        </a>
                    </>
                )

            }
            else {
                return (
                    <>
                    
                        <a className="menu-item">
                            <Link className="nav-link" to="/register">
                                <Button className="btn-header" color="primary" >Inscription</Button>
                            </Link>
                        </a>
                        <a className="menu-item" >
                            <Link className="nav-link" to="/login">
                                <Button className="btn-header" color="primary" >Connexion</Button>
                            </Link>
                        </a>
                    </>
                );
            }

        }
        //Displays sidebar when mobile view
        const DisplaySidebar = () => {
            
            if (this.state.hideNav) {
                return ( 
                    <> 
                        <Navbar> 
                            <Nav className="ml-auto">
                                <NavbarBrand
                                    href="/home"
                                    style={{ lineHeight: "30px" }}
                                >
                                    <div>
                                        <h2 className="d-inline-block ml-auto">
                                            <span className="text-primary">eudeliv</span>
                                        </h2>
                                    </div> 
                                </NavbarBrand>
                            </Nav>
                        </Navbar>
                        <Menu> 
                                <NavItem className="menu-item">
                                    <Link className="nav-link" to="/searchannouncements">
                                        <span style={{color : "#00AFF5"}} className="fa fa-search fa-lg mr-1"></span><strong className="text-primary">Rechercher</strong>
                                    </Link>
                                </NavItem>

                                <NavItem className="menu-item">
                                    <Link className="nav-link" to="/adddelivery">
                                        <span style={{color : "#00AFF5"}}className="fa fa-cube fa-lg mr-1"></span><strong className="text-primary">Envoyer un colis</strong>
                                    </Link>
                                </NavItem>

                                <NavItem className="menu-item">
                                    <Link className="nav-link" to="/addtrip">
                                        <span style={{color : "#00AFF5"}} className="fa fa-road fa-lg mr-1"></span><strong className="text-primary">Transporter un colis</strong>
                                    </Link>
                                </NavItem>
                                <RenderLoginNavbar />
                        </Menu>
            </>
            )  
            } return(<></>)
        }
        //Displays navbar when web view
        const NoSidebar = () =>  {
            if (!this.state.hideNav) {
                return(
                    <>
                    <Navbar light expand="md"> 
                        <NavbarBrand
                            href="/home"
                            style={{ lineHeight: "30px" }}
                        >
                            <div>
                                <h2 className="d-inline-block ml-0">
                                    <span className="text-primary">eudeliv</span>
                                </h2>
                            </div> 
                        </NavbarBrand>
                        <Nav className="ml-auto " navbar>
                            <NavItem className="d-none d-lg-block">
                                <Link className="nav-link" to="/searchannouncements">
                                    <span style={{color : "#00AFF5"}} className="fa fa-search fa-lg mr-1"></span><strong className="text-primary">Rechercher</strong>
                                </Link>

                            </NavItem>

                            <NavItem>
                                <Link className="nav-link" to="/adddelivery">
                                    <span style={{color : "#00AFF5"}}className="fa fa-cube fa-lg mr-1"></span><strong className="text-primary">Envoyer un colis</strong>
                                </Link>
                            </NavItem>

                            <NavItem>
                                <Link className="nav-link" to="/addtrip">
                                    <span style={{color : "#00AFF5"}} className="fa fa-road fa-lg mr-1"></span><strong className="text-primary">Transporter un colis</strong>
                                </Link>
                            </NavItem>
                            <RenderLoginNavbar/>
                        </Nav>
                    </Navbar>
                </>
                )
            } return(<></>)
        }
        return (
            <>
                <DisplaySidebar/>
                <NoSidebar />
            </>
        );
    }
}
            
export default Header; 
                    