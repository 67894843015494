import React from 'react';
import { Link } from 'react-router-dom';

function Footer (props){
  if(window.location.pathname === '/login' || window.location.pathname === '/register' || window.location.pathname.split('/')[1] === 'userprofile' || 
   window.location.pathname === '/addtrip' || window.location.pathname === '/adddelivery' || window.location.pathname === '/mytrips' || window.location.pathname === '/profil'
   || window.location.pathname.split('/')[1] === 'mytrips'
   || window.location.pathname === '/mydeliveries' || window.location.pathname.split('/')[1] === 'mydeliveries'
   || window.location.pathname === '/searchannouncements'   || window.location.pathname.split('/')[1] === 'announcements'
   || window.location.pathname === '/announcements' || window.location.pathname === '/messages'
   || window.location.pathname.split('/')[1] === 'messages' || window.location.pathname === '/demandresetpassword') 
    return (<div></div>);
 else {
    return(
     
      
        <div className="footer">
        <div className="container">
            <div className="row justify-content-center">             
                <div className="col-6 offset-1 col-sm-3">
                    <h5>A PROPOS</h5>
                    <ul className="list-unstyled">
                     <li><Link to="/contact"><span className="text-muted">Contact</span></Link></li>
                        <li><Link to="/history"><span className="text-muted">Notre histoire</span></Link></li>
                        <li><Link to="/howitworks"><span className="text-muted">Comment ça marche</span></Link></li>
                        <li><Link to="/conditionutilisation"><span className="text-muted">Conditions d'utilisation</span></Link></li>
                        
                      
                    </ul>
                </div>
                <div className="col-7 col-sm-3">
                    
                </div>
                <div className="col-12 col-sm-4 align-self-center">
                    <div className="text-center">
                         <a className="btn btn-social-icon btn-instagram" href="https://www.instagram.com/eudeliv/"><i className="fa fa-instagram"></i></a> &nbsp;
                        <a className="btn btn-social-icon btn-facebook" href="https://www.facebook.com/eudeliv-103571514714472/"><i className="fa fa-facebook"></i></a>&nbsp;
                        <a className="btn btn-social-icon btn-linkedin" href="https://www.linkedin.com/company/eudeliv/?viewAsMember=true"><i className="fa fa-linkedin"></i></a>&nbsp;
                        {/* <a className="btn btn-social-icon btn-twitter" href="http://twitter.com/"><i className="fa fa-twitter"></i></a> */}
                        <a className="btn btn-social-icon btn-google" href="https://www.youtube.com/watch?v=t6bhZzGZ7kg&t"><i className="fa fa-youtube"></i></a>
                        <a className="btn btn-social-icon" href="mailto:contact@eudeliv.com"><i className="fa fa-envelope-o"></i></a>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">             
                <div className="col-auto" style={{color : "#6c757d"}}>
                    <p>© Copyright 2020 eudeliv</p>
                </div>
            </div>
        </div>
    </div>
 


    )
 }
}

export default Footer