import React from 'react';
import { DropdownItem, CardBody, Col, Row,CardText } from 'reactstrap';
import { Loading } from '../common/LoadingComponent';



function RenderUserProfile({ user }) {
    const getAge = (date) => {
        var today = new Date();
        var birthDate = new Date(date);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age = age - 1;
        }

        return age;
    }
    return (
        <div key={user.id} >
            <div >
                <Col>
                    <CardBody className="border-bottom text-center">
                        <div className="mb-3 mx-auto">
                            <img
                                className="rounded-circle"
                                src="/assets/images/profil.svg"
                                alt={user.firstName}
                                width="87" height="87"
                            />
                        </div>
                        <h4 className="mb-0">{user.firstName} {user.lastName}</h4>
                        <span className="text-muted d-block mb-2"> {getAge(new Intl.DateTimeFormat('en-US').format(user.birthDate))} ans</span>
                    </CardBody>
                    <CardBody className="text-muted">
                        <CardText> Particulier </CardText>
                        <DropdownItem divider />
                        <CardText> <i className="fa fa-mobile"></i> <strong>Numéro : </strong>  {user.numberMobile} </CardText>
                        <CardText> <i className="fa fa-envelope"></i> <strong>Email : </strong>  { user.email.includes('@') ? user.email : '' } </CardText>
                        <DropdownItem divider />
                        <CardText> Membre depuis {new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: 'long' }).format(user.dateCreation)}  </CardText>
                    </CardBody>
                </Col>
            </div>
        </div>
    )
}

const UserProfil = (props) => {
    
    if (props.isLoading != false) {
        return (
            <div className="container">
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <Loading />
                    </Col>
                </Row>
            </div>
        );
    }
   else if (props.userProfile != null) {
        return (
            <div className="container">
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <br />
                        <RenderUserProfile user={props.userProfile.user} />
                        <br/><br/><br/>
                    </Col>
                </Row>
            </div>
        );
        } 
    else if (props.userProfileFromMessages != null) 
        return (
            <div className="container">
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <br />
                        <RenderUserProfile user={props.userProfileFromMessages.user} />
                        <br/><br/><br/>
                    </Col>
                </Row>
            </div>
        );    
    
    else {
        return (
            <div className="container">
                <div className="row">
                    <h4>500 ERROR</h4>
                </div>
            </div>
        );
    }

}


export default UserProfil;


