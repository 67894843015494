import React, { Component } from 'react';
import { Button, Col, Row} from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { Loading } from '../common/LoadingComponent';
import  SocialLogin  from '../common/SocialLoginComponent';

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class Login extends Component {

    constructor(props) {
        super(props);

        this.handleLogin = this.handleLogin.bind(this);
        this.props.resetLoginForm();
    } 
    handleLogin(values) {
        this.props.authenticate(values.email,values.password,this.props.TokenDeviseRegister);  
        this.props.resetLoginForm();
    }

      
    render() {
      
        const  RenderLoginFacebook = ({isLogin}) => {
     
            if (isLogin) {
                return (
                    <Loading />
                );
            } 
            else {
                return(
                            <SocialLogin isNotAndroidPlatform={this.props.isNotAndroidPlatform} TokenDeviseRegister={this.props.TokenDeviseRegister} />
                  
                    )
            }
        }
        
        const  RenderLogin = ({isLogin}) => {
            if (isLogin) {
                return (
                    <Loading />
                );
            } 
            else {
                return (
                    <LocalForm onSubmit={(values) => this.handleLogin(values)} >
                    <Row className="form-group">
                        <Col>
                            <Control.text model=".email" id="email" name="email" innerRef={(input) => this.email = input}
                                placeholder="E-mail"
                                className="form-control"
                                validators={{
                                    required, validEmail
                                }} />
                            <Errors
                                className="errors"
                                model=".email"
                                show="touched"
                                messages={{
                                    required: ' Veuillez saisir votre email.',
                                    validEmail: ' Vérifiez votre email, son format est invalide.'
                                }}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row className="form-group">
                        <Col>
                            <Control.password   model=".password" id="password" name="password" innerRef={(input) => this.password = input}
                                placeholder="Mot de passe"
                                className="form-control"
                                validators={{
                                    required,maxLength: maxLength(30), minLength: minLength(8)
                                }} />
                            <Errors
                                className="errors"
                                model=".password"
                                show="touched"
                                messages={{
                                    required: ' Veuillez saisir votre mot de passe.',
                                    maxLength: '  30 caractères maximum',
                                    minLength: ' 8 caractères minimum'
                                }}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Button  type="submit" value="submit" color="primary"><strong>Connexion</strong></Button>
                    <div className="text-center">
                    <br/>
                      <Link to="/demandresetpassword" className="btn btn-link">  <h5>Mot de passe oublié ?</h5> </Link>   
                      <Link to="/register" className="btn btn-link">  <h5>Nous rejoindre ? Créer un compte</h5> </Link>  
                     </div>  
                     <br/><br/> 
                </LocalForm>
        
                );
        
            }
        
        }
        return (
            <div class="container">
                <div sm="12" md={{ size: 9, offset: 0 }} className="container  text-center">      
                    <br/>
        <h3>Identifiez-vous</h3>  
        <br/>
        <RenderLoginFacebook />
              </div> 
                <div className="Login text-center">
                    <RenderLogin isLogin = {this.props.isLogin}/>                   
                </div>
                <br/><br/>
            </div>
        );
                              
    }

}

export default Login;