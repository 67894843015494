import React, { Component } from 'react';
import { Control, Form, Errors } from 'react-redux-form';
import {  Col, Row } from 'reactstrap';
import { Loading } from '../common/LoadingComponent';
import { Fade, Stagger } from 'react-animation-components';
import { Progress } from 'reactstrap';
import DatePicker from "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import  SocialLogin  from '../common/SocialLoginComponent';





const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            birthDate:null
        };
        
        this.handleRegister = this.handleRegister.bind(this);
        this.nextStepForm = this.nextStepForm.bind(this);
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
        this.props.resetRegisterForm();
    }
   
    
    handleBirthDateChange(data) {
        this.setState({
            birthDate : data
        });
    
    }
    nextStepForm() {
        this.setState({
            currentStep: this.state.currentStep + 1
        });
    }


    handleRegister(values) {
        this.props.register(values);
        this.props.resetRegisterForm();
    }
    
    render() {

        const RenderNextButton = () => {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 9, offset: 0 }}>
                        <Control.button
                            className="btn btn-primary"
                            onClick={this.nextStepForm}
                            model="registerForm"
                            disabled={{ valid: false }} >
                            
                            Continuer
                     </Control.button>
                    </Col>
                </Row>
            );

        }
        const RenderPersonalInformation = ({ currentStep }) => {
            if (currentStep === 0)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Créez votre compte:</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">
                            <Col md={10}>
                                <Control.text  model=".lastName" id="lastName" name="lastName"
                                    placeholder="Nom"
                                    className="form-control"
                                    validators={{
                                        required, maxLength: maxLength(20)
                                    }} 
                                    />
                                <Errors
                                    className="errors"
                                    model=".lastName"
                                    show="touched"
                                    messages={{
                                        required: '   Veuillez saisir votre nom.',
                                        maxLength: '  20 caractères maximum.'
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md={10}>
                                <Control.text model=".firstName" id="firstName" name="firstName"
                                    placeholder="Prénom"
                                    className="form-control"
                                    validators={{
                                        required, maxLength: maxLength(20)
                                    }}
                                    
                                />
                                <Errors
                                    className="errors"
                                    model=".firstName"
                                    show="touched"
                                    messages={{
                                        required: ' Veuillez saisir votre prénom.',
                                        maxLength: ' 20 caractères maximum'
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <RenderNextButton />
                        <br/>
                        <Row className="text-center">
                    <Col sm="12" md={{ size: 9, offset: 0 }}>
                      <SocialLogin  isNotAndroidPlatform={this.props.isNotAndroidPlatform} TokenDeviseRegister={this.props.TokenDeviseRegister}/>
                        </Col>
                        </Row>
                        <br/><br/>
                    </React.Fragment>
                );
            else
                return (
                    <div></div>
                )
        }
        const RenderEmailAndPassword = ({ currentStep }) => {
            if (currentStep === 1)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre email et mot de passe :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">
                            <Col md={10}>
                                <Control.text model=".email" id="email" name="email"
                                    placeholder="Email"
                                    className="form-control"
                                    autoFocus="true"
                                    validators={{
                                        required, validEmail
                                    }}
                                />
                                <Errors
                                    className="errors"
                                    model=".email"
                                    show="touched"
                                    messages={{
                                        required: ' Veuillez saisir votre email.',
                                        validEmail: ' Vérifiez votre email, son format est invalide.'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col md={10}>
                                <Control.password model=".password" id="password" name="password"
                                    placeholder="Mot de passe"
                                    className="form-control"
                                    validators={{
                                        required,maxLength: maxLength(30), minLength: minLength(8)
                                    }}
                                />
                                <Errors
                                    className="errors"
                                    model=".password"
                                    show="touched"
                                    messages={{
                                        required: ' Veuillez saisir votre mot de passe.',
                                        maxLength: '  30 caractères maximum',
                                        minLength: ' 8 caractères minimum'
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <RenderNextButton />

                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }
        const RenderBirthDate = ({ currentStep }) => {
            if (currentStep === 2)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre date de naissance :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group text-center">
                            <Col md={10}>
                            <Control type="date" model=".birthDate" id="birthDate" name="birthDate"
                                    className="form-control"
                                    component={DatePicker}
                                    onChange={this.handleBirthDateChange} 
                                    maxDate={new Date()}
                                    selected={this.state.birthDate}
                                    value={this.state.birthDate}
                                    dateFormat=" dd-MM-yyyy"
                                    placeholderText="date de naissance"
                                    locale={fr}      
                                    validators={{
                                        required
                                    }}   
                                    isClearable  
                                    showMonthDropdown
                                    showYearDropdown 
                                    showYearDropdown
                                    dropdownMode="select"             
                                />          
                            </Col>
                        </Row>
                        <br />
                        <Row className="text-center">
                    <Col sm="12" md={{ size: 9, offset: 0 }}>
                        <Control.button
                            className="btn btn-primary"
                            onClick={this.nextStepForm}
                            model="registerForm"
                            disabled={ this.state.birthDate === null}  >
                            Continuer
                     </Control.button>
                    </Col>
                </Row>

                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }
        // const RenderAddress = ({ currentStep }) => {
        //     if (currentStep === 3)
        //         return (
        //             <React.Fragment>
        //                 <Row className="text-center">
        //                     <Col sm="12" md={{ size: 9, offset: 0 }}>
        //                         <br />
        //                         <h4>Votre adresse :</h4>
        //                     </Col>
        //                 </Row>
        //                 <br /><br />
        //                 <Row className="form-group">
        //                     <Col md={10}>
        //                         <Control.text model=".streetAddress" id="streetAddress" name="streetAddress"
        //                             placeholder="Adresse"
        //                             className="form-control"
        //                             autoFocus="true"
        //                             validators={{
        //                                 required
        //                             }}
        //                         />
        //                         <Errors
        //                             className="errors"
        //                             model=".streetAddress"
        //                             show="touched"
        //                             messages={{
        //                                 required: ' Veuillez saisir votre adresse.',
        //                             }}
        //                         />
        //                     </Col>
        //                 </Row>
        //                 <Row className="form-group">
        //                     <Col md={10}>
        //                         <Control.text model=".zipCode" id="zipCode" name="zipCode"
        //                             placeholder="Code postal"
        //                             className="form-control"
        //                             validators={{
        //                                 required,maxLength: maxLength(5), isNumber
        //                             }}
        //                         />
        //                         <Errors
        //                             className="errors"
        //                             model=".zipCode"
        //                             show="touched"
        //                             messages={{
        //                                 required: ' Veuillez saisir votre code postal.',
        //                                 isNumber : ' Veuillez saisir que des chiffres.',
        //                                 maxLength: '  5 caractères maximum',
        //                             }}
        //                         />
        //                     </Col>
        //                 </Row>
        //                 <Row className="form-group">
        //                     <Col md={10}>
        //                         <Control.text model=".city" id="city" name="city"
        //                             placeholder="Ville"
        //                             className="form-control"
        //                             validators={{
        //                                 required,maxLength: maxLength(30)
        //                             }}
        //                         />
        //                         <Errors
        //                             className="errors"
        //                             model=".city"
        //                             show="touched"
        //                             messages={{
        //                                 required: ' Veuillez saisir votre ville.',
        //                                 maxLength: '  30 caractères maximum',
        //                             }}
        //                         />
        //                     </Col>
        //                 </Row>
        //                 <Row className="form-group">
        //                     <Col md={10}>
        //                         <Control.text model=".country" id="country" name="country"
        //                             placeholder="Pays"
        //                             className="form-control"
        //                             validators={{
        //                                 required,maxLength: maxLength(30)
        //                             }}
        //                         />
        //                         <Errors
        //                             className="errors"
        //                             model=".country"
        //                             show="touched"
        //                             messages={{
        //                                 required: ' Veuillez saisir votre pays.',
        //                                 maxLength: '  30 caractères maximum',
        //                             }}
        //                         />
        //                     </Col>
        //                 </Row>
        //                 <br />
        //                 <RenderNextButton />
        //             </React.Fragment>

        //         );
        //     else
        //         return (
        //             <div></div>
        //         )
        // }
        const RenderMobile = ({ currentStep }) => {
            if (currentStep === 3)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre numéro de téléphone :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">
                            <Col md={10}>
                                <Control.text model=".numberMobile" id="numberMobile" name="numberMobile"
                                    placeholder="Numéro de téléphone"
                                    autoFocus="true"
                                    className="form-control"
                                    validators={{
                                        required, minLength: minLength(3), maxLength: maxLength(15), isNumber
                                    }}
                                />
                                <Errors
                                    className="errors"
                                    model=".numberMobile"
                                    show="touched"
                                    messages={{
                                        required: ' Veuillez saisir votre numéro de téléphone.',
                                        maxLength: ' 30 caractères maximum',
                                        minLength: ' 3 caractères minimum',
                                        isNumber : ' Veuillez saisir que des chiffres.'
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row className="form-group text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <Control.button className="btn btn-primary" disabled={{ valid: false }} model="registerForm" type="submit" color="primary">
                                    Inscrire
                                    </Control.button>
                            </Col>
                        </Row>
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }
        const RenderRegister = ({ isRegister, responseRegister }) => {
            if (isRegister) {
                return (
                    <Row className="text-center">
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Loading />
                        </Col>
                    </Row>

                );
            } 
            else {
                return (
                    <div className="container">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                                <Form name="registerForm" validateOn="change" model="registerForm" onSubmit={(values) => this.handleRegister(values)}>
                                    <Stagger in>
                                        <Fade in>
                                            <RenderPersonalInformation currentStep={this.state.currentStep} />
                                        </Fade>
                                        <Fade in>
                                            <RenderEmailAndPassword currentStep={this.state.currentStep} />
                                        </Fade>
                                            <RenderBirthDate currentStep={this.state.currentStep} />
                                        <Fade in>
                                            <RenderMobile currentStep={this.state.currentStep} />
                                        </Fade>
                                    </Stagger>


                                </Form>
                                <br/><br/><br/>
                            </Col>
                        </Row>
                    </div>
                );
            }
        }

        //   const register = this.props.forms.register;
        const RenderProgress = ({responseRegister}) => {
            var progress = (this.state.currentStep !== 3) ? this.state.currentStep * 33 : 100;
            if (!responseRegister)
                return (

                    <Row >
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Progress value={progress} />
                        </Col>
                    </Row>
                );
            else
                return (
                    <div></div>
                );
        }
        return (
            <div>
                <br />
                <RenderProgress responseRegister={this.props.responseRegister} />
                <RenderRegister isRegister={this.props.isRegister} responseRegister={this.props.responseRegister} />
            </div>
        );



    }
}

export default Register;