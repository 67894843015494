import React, { Component } from 'react';
import Main from './components/MainComponent';
//import Navigation from './components/common/NavigationComponent';
import './App.css';
//import {BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';
import { Router } from 'react-router';
import { history } from  './redux/helpers/history'



const store =  ConfigureStore();

class App extends Component {


  
render() {
  return (
    <Provider store ={store}>
     <Router history={history}>
        <div>
          <Main />
        </div>
    </Router>
    </Provider>
  );
}

}
export default App;

