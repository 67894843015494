import React, { Component } from 'react';
import Alert from '@material-ui/lab/Alert';
import Home from './home/HomeComponent';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import Navigation from './common/NavigationComponent';
import Contact from './apropos/ContactComponent';
import ConditionUtilisation from './apropos/ConditionUtilisationComponent';
import History from './apropos/HistoryComponent';
import HowItWorks from './apropos/HowItWorksComponent';
import Login from './user/LoginComponent';
import UserProfile from './user/UserProfileComponent';
import Profil from './user/ProfilComponent';
import Register from './user/RegisterComponent';
import ResetPassword from './user/ResetPasswordComponent';
import DemandResetPassword from './user/DemandResetPasswordComponent';
import AddTrip from './trip/AddtripComponent';
import AddDelivery from './delivery/addDeliveryComponent';
import MyTrips from './trip/MyTrips.component';
import MyDeliveries from './delivery/MyDeliveriesComponent';
import MyTripDetail from './trip/MyTripDetail.component';
import MyDeliveryDetail from './delivery/MyDeliveryDetailComponent'
import AnnouncementDetail from './announcement/AnnouncementDetail.component'
import Announcements from './announcement/Announcements.component';
import SearchAnnouncements from './announcement/SearchAnnouncementComponent';
import Messages from './messages/MessagesComponent';
import MessageDetail from './messages/MessageDetailComponent';
import { Loading } from './common/LoadingComponent';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { autenticate,logout, sendEmailResetPassword, resetPassword, register,updateUser,updateTokenDevise} from '../redux/actions/user.actionCreator';
import { addTrip } from '../redux/actions/addTrip.actionCreator';
import { addDelivery } from '../redux/actions/addDelivery.actionCreator';
import { searchAnnouncements,searchAnnouncement} from '../redux/actions/searchAnnouncements.actionCreator';
import { fetchMyTrips, deleteTrip } from '../redux/actions/myTrips.actionCreator';
import { fetchMyDeliveries,deleteDelivery} from '../redux/actions/myDeliveries.actionCreator';
import { fetchMessages, sendMessage } from '../redux/actions/message.actionCreator';
import { fetchNotifications, updateNotification } from '../redux/actions/notification.actionCreator';
import { clear } from '../redux/actions/alert.actionCreator';
import { history } from '../redux/helpers/history';
import { actions } from 'react-redux-form';
import { PrivateRoute } from './common/PrivateRoute';
import { currentUser } from '../redux/helpers/currentUser';




/* you can use it in the props state ( store) */
const mapStateToProps = state => {
  return {
    user: state.user,
    alert: state.alert,
    registerUser: state.registerUser,
    addTripState: state.addTrip,
    addDeliveryState: state.addDelivery,
    myTrips: state.myTrips,
    myDeliveries: state.myDeliveries,
    announcements: state.announcements,
    messages: state.messages,
    notifications: state.notifications
  }
}
/* you can use it in the props action*/
const mapDispatchToProps = dispatch => ({
  autenticate: (email, password,tokenDevise) => dispatch(autenticate(email, password,tokenDevise)),
  register: (user) => dispatch(register(user)),
  updateUser : (user) => dispatch(updateUser(user)),
  updateTokenDevise : (tokenDevise) => dispatch(updateTokenDevise(tokenDevise)),
  sendEmailResetPassword: (email) => dispatch(sendEmailResetPassword(email)),
  resetPassword: (token, password) => dispatch(resetPassword(token, password)),
  addTrip: (trip) => dispatch(addTrip(trip)),
  addDelivery: (delivery) => dispatch(addDelivery(delivery)),
  fetchMyTrips: () => dispatch(fetchMyTrips()),
  fetchMyDeliveries: () => dispatch(fetchMyDeliveries()),
  deleteTrip : (idTrip) => dispatch(deleteTrip(idTrip)),
  deleteDelivery : (idDelivery) => dispatch(deleteDelivery(idDelivery)),
  fetchMessages: () => dispatch(fetchMessages()),
  fetchNotifications: () => dispatch(fetchNotifications()),
  updateNotification: (idAnnouncement, idUser, IdUserSender) => dispatch(updateNotification(idAnnouncement, idUser, IdUserSender)),
  sendMessage: (message) => dispatch(sendMessage(message)),
  fetchAnnouncements: (departure, destination, dateDeparture) => dispatch(searchAnnouncements(departure, destination, dateDeparture)),
  fetchAnnouncement: () => dispatch(searchAnnouncement()),
  logout: () => dispatch(logout()),
  resetLoginForm: () => { dispatch(actions.reset('loginForm')) },
  resetRegisterForm: () => { dispatch(actions.reset('registerForm')) },
  resetAddTripForm: () => { dispatch(actions.reset('addTripForm')) },
  resetAddDeliveryForm: () => { dispatch(actions.reset('addDeliveryForm')) },
  clearAlerts: () => dispatch(clear())

});

class Main extends Component {


  constructor(props) {
    super(props);
    this.state = {
      previousLocation: history.location.pathname,
      isNotAndroidPlatform:true,
      isTokenDeviseRegister:true,
      TokenDeviseRegister:null
    }

    history.listen((location, action) => {
      
      this.setState({
        previousLocation: location.pathname
      })
      if (window.ReactNativeWebView != undefined ) {
        window.ReactNativeWebView.postMessage(window.location.pathname)
      }
     
      this.props.clearAlerts();
    });
  }

  componentDidMount() {
    document.addEventListener("message", this.handleNativeMessage);
    window.addEventListener("message", this.handleNativeMessage);
    // need te dispatch those actions  when user login 
    if (this.props.location.search !==  "" ){
      this.props.fetchAnnouncement();
    }
    this.props.fetchMessages();
    this.props.fetchMyTrips();
    this.props.fetchMyDeliveries();
    this.props.fetchNotifications();   

  }
  componentWillUnmount() {
    document.removeEventListener('message', this.handleNativeMessage);
    window.removeEventListener('message', this.handleNativeMessage);
}
  handleNativeMessage = (e) => {
// detect if device android
if(e.data){
let event = JSON.parse(e.data)
    if (event.type == 'androiApp') { 
        this.setState({
            isNotAndroidPlatform: false
         })                   
    }
    if (event.type == 'tokenDeviseRegister') { 
      
       if(event.data && this.state.isTokenDeviseRegister){
        this.props.updateTokenDevise(event.data);
        this.setState({
          isTokenDeviseRegister: false,
          TokenDeviseRegister : event.data
         })  
       }               
  }
}
}

  render() {
    const { alert } = this.props;
    const LoginPage = () => {
      return (
        <Login
          isLogin={this.props.user.isLogin}
          user={this.props.user}
          authenticate={this.props.autenticate}
          resetLoginForm={this.props.resetLoginForm}
          isNotAndroidPlatform={this.state.isNotAndroidPlatform}
          TokenDeviseRegister={this.state.TokenDeviseRegister}
          updateTokenDevise={this.props.updateTokenDevise}
        />
      );
    }

    const RegisterPage = () => {
      return (
        <Register
          register={this.props.register}
          isRegister={this.props.registerUser.isRegister}
          resetRegisterForm={this.props.resetRegisterForm}
          responseRegister={this.props.registerUser.responseRegister}
          isNotAndroidPlatform={this.state.isNotAndroidPlatform}
          TokenDeviseRegister={this.state.TokenDeviseRegister}
        />
      );
    }

    const UserProfileWithId = ({ match }) => {
      if (this.props.location.state.from.split('/')[1] === 'announcements')
        return (
          <UserProfile
            userProfile={this.props.announcements.responseSearchAnnouncements.filter((AnnouncementWithUser) => AnnouncementWithUser.user.id === parseInt(match.params.userId, 10))[0]}
            userProfileFromMessages={AnnouncementAndUser().filter((item) => item.user.id === parseInt(match.params.userId, 10))[0]}
            isLoading={this.props.announcements.isSearchAnnouncements}
          />
        );
      else if (this.props.location.state.from.split('/')[1] === 'messages') {
        return (
          <UserProfile
            userProfile={AnnouncementAndUser().filter((item) => item.user.id === parseInt(match.params.userId, 10))[0]}
            userProfileFromMessages={this.props.announcements.responseSearchAnnouncements.filter((item) => parseInt(item.user.id, 10) === parseInt(match.params.userId, 10))[0]}
            isLoading={this.props.messages.isLoadingMessages}
          />
        );
      }

    }

    const currentUserProfilePage = () => {
      return (
        <Profil user={this.props.user.user}
                updateUser={this.props.updateUser}
                isLogin={this.props.user.isLogin}
        />
      );
    }
    const DemandResetPasswordPage = () => {
      return (
        <DemandResetPassword sendEmailResetPassword={this.props.sendEmailResetPassword}
        />
      );
    }

    const ResetPasswordWithToken = ({ match }) => {
      return (
        <ResetPassword
          token={match.params.token}
          isUpdatingPassword={this.props.user.isLogin}
          updatePassword={this.props.resetPassword}
        />
      );
    }

    const AddTripPage = () => {
      return (
        <AddTrip
          isAddingTrip={this.props.addTripState.isAddingTrip}
          resetAddTripForm={this.props.resetAddTripForm}
          responseAddTrip={this.props.addTripState.responseAddTrip}
          addTrip={this.props.addTrip}
        />
      );
    }

    const AddDeliveryPage = () => {
      return (
        <AddDelivery
          isAddingDelivery={this.props.addDeliveryState.isAddingDelivery}
          resetAddDeliveryForm={this.props.resetAddDeliveryForm}
          responseAddDelivery={this.props.addDeliveryState.responseAddDelivery}
          addDelivery={this.props.addDelivery}
        />
      );
    }

    const MyTripsPage = () => {
      return (
        <MyTrips myTrips={this.props.myTrips} />
      );
    }

    const MyDeliveriesPage = () => {
      return (
        <MyDeliveries myDeliveries={this.props.myDeliveries} />
      );
    }

    const AnnouncementsPage = () => {
      return (
        <Announcements announcementsWithUser={this.props.announcements} />
      );
    }

    const SearchAnnouncementsPage = () => {
      return (
        <SearchAnnouncements
          isSearchAnnouncements={this.props.announcements.isSearchAnnouncements}
          responseSearchAnnouncements={this.props.announcements.responseSearchAnnouncements}
          fetchAnnouncements={this.props.fetchAnnouncements}
        />
      );
    }

    const HomePage = () => {
      return (
        <Home
          isSearchAnnouncements={this.props.announcements.isSearchAnnouncements}
          fetchAnnouncements={this.props.fetchAnnouncements}
        />
      );
    }
    const MyTripWithId = ({ match }) => {
      return (
        <MyTripDetail trip={this.props.myTrips.myTrips.filter((trip) => trip.id === parseInt(match.params.tripId, 10))[0]}
          isLoading={this.props.myTrips.isLoadingTrips}
          ErrMess={this.props.myTrips.errMess}
          deleteTrip={this.props.deleteTrip} 
        />
      );

    }

    const MyDeliveryWithId = ({ match }) => {
      return (
        <MyDeliveryDetail delivery={this.props.myDeliveries.myDeliveries.filter((delivery) => delivery.id === parseInt(match.params.deliveryId, 10))[0]}
          isLoading={this.props.myDeliveries.isLoadingMyDeliveries}
          ErrMess={this.props.myDeliveries.errMess}
          deleteDelivery={this.props.deleteDelivery}
        />
      );

    }

    const AnnouncementWithId = ({ match }) => {
      if (this.props.location.state && this.props.location.state.from.split('/')[1] === 'announcements') {
        const announcementUser = this.props.announcements.responseSearchAnnouncements.filter((announcementWithUser) => announcementWithUser.announcement.id === parseInt(match.params.announcementId, 10))[0];
        if (announcementUser.announcement.idUser !== currentUser().id)
          return (
            <AnnouncementDetail announcementWithUser={announcementUser}
              isLoading={this.props.announcements.isSearchAnnouncements}
              errMess={this.props.announcements.errMess}
            />
          );
        else
        {
          if(announcementUser.announcement.dateDestination)
          return (
            <Redirect to={{ pathname: '/mytrips/' + parseInt(match.params.announcementId, 10), state: { from: this.props.location } }} />
          )
          else
          return (
            <Redirect to={{ pathname: '/mydeliveries/' + parseInt(match.params.announcementId, 10), state: { from: this.props.location } }} />
          )

        } 

      }


      else if (this.props.location.state && this.props.location.state.from.split('/')[1] === 'messages') {

        if (this.props.messages.isLoadingMessages) {
          return (
            <Loading />
          )
        }

        else {
          const announcementUser = AnnouncementAndUser().filter((item) => item.announcement.id === parseInt(match.params.announcementId, 10))[0];
          if (!announcementUser)
            return (
              <AnnouncementDetail announcementWithUser={this.props.announcements.responseSearchAnnouncements.filter((announcementWithUser) => announcementWithUser.announcement.id === parseInt(match.params.announcementId, 10))[0]}
                isLoading={this.props.announcements.isSearchAnnouncements}
                errMess={this.props.announcements.errMess}
              />
            ); else {
            if (announcementUser.announcement.idUser !== currentUser().id)
              return (
                <AnnouncementDetail announcementWithUser={announcementUser}
                  isLoading={this.props.messages.isLoadingMessages}
                  errMess={this.props.messages.errMess}
                />
              );

            else
            {
              if(announcementUser.announcement.dateDestination)
              return (
                <Redirect to={{ pathname: '/mytrips/' + parseInt(match.params.announcementId, 10), state: { from: this.props.location } }} />
              )
              else
              return (
                <Redirect to={{ pathname: '/mydeliveries/' + parseInt(match.params.announcementId, 10), state: { from: this.props.location } }} />
              )
    
            } 
          }
        }
      }
      else if (this.props.location.state ===  undefined  ) {
        
        return (
          <AnnouncementDetail announcementWithUser={this.props.announcements.responseSearchAnnouncements.filter((announcementWithUser) => announcementWithUser.announcement.id === parseInt(match.params.announcementId, 10))[0]}
            isLoading={this.props.announcements.isSearchAnnouncements}
            errMess={this.props.announcements.errMess}
          />
        );
      }

    }




    const messageOrderByannouncement = this.props.messages.messages.reduce((result, currentValue) => {
      (result[currentValue['announcement']['id'] * 99 * currentValue['userSender']['id'] * currentValue['userReceiver']['id']] =
        result[currentValue['announcement']['id'] * 99 * currentValue['userSender']['id'] * currentValue['userReceiver']['id']] || []).push(
          currentValue
        );
      return result;
    }, {});




    const AnnouncementAndUser = () => {
      var result = [];
      Object.values(messageOrderByannouncement).forEach((item) => {
        if (item[0].userSender.id !== currentUser().id)
          result.push({
            user: item[0].userSender,
            announcement: item[0].announcement,
            message: item[0].message
          })
        else
          result.push({
            user: item[0].userReceiver,
            announcement: item[0].announcement,
            message: item[0].message
          })


      })
      return result;

    }
    const MessagesPage = () => {

      return (
        <Messages conversations={AnnouncementAndUser()}
          isLoading={this.props.messages.isLoadingMessages}
          errMess={this.props.messages.errMess}
          notifications={this.props.notifications.notifications}
          isLoading={this.props.notifications.isLoadingNotifications}
          updateNotification={this.props.updateNotification} />
      );
    }

    const MessageWithId = ({ match }) => {
      if (this.props.location.state.from.split('/')[1] === 'messages')
        return (
          <MessageDetail
            messages={Object.values(messageOrderByannouncement).filter((item) => {
              if (item[0].userSender.id !== currentUser().id && parseInt(item[0].announcement.id + '' + item[0].userSender.id, 10) === parseInt(match.params.conversationId, 10))
                return true;
              else if (item[0].userSender.id === currentUser().id && parseInt(item[0].announcement.id + '' + item[0].userReceiver.id, 10) === parseInt(match.params.conversationId, 10))
                return true;
              else return false
            }
            )[0]}
            isLoading={this.props.messages.isLoadingMessages}
            errMess={this.props.messages.errMess}
            sendMessage={this.props.sendMessage}
          />
        );
      else if (this.props.location.state.from.split('/')[1] === 'announcements')
        return (
          <MessageDetail
            messages={Object.values(messageOrderByannouncement).filter((item) => {
              if (item[0].userSender.id !== currentUser().id && parseInt(item[0].announcement.id + '' + item[0].userSender.id, 10) === parseInt(match.params.conversationId, 10))
                return true;
              else if (item[0].userSender.id === currentUser().id && parseInt(item[0].announcement.id + '' + item[0].userReceiver.id, 10) === parseInt(match.params.conversationId, 10))
                return true;
              else return false
            }
            )[0]}
            messagesFirstContact={this.props.announcements.responseSearchAnnouncements.filter((item) => parseInt(item.announcement.id + '' + item.user.id, 10) === parseInt(match.params.conversationId, 10))[0]}
            isLoading={this.props.messages.isLoadingMessages}
            errMess={this.props.messages.errMess}
            sendMessage={this.props.sendMessage}
          />
        )

    }
    return (
      <div>
        <Navigation notifications={this.props.notifications.notifications}
          isLoading={this.props.notifications.isLoadingNotifications} />
        <Header logout={this.props.logout}
          notifications={this.props.notifications.notifications}
          isLoading={this.props.notifications.isLoadingNotifications} />
        {alert.message &&
          <Alert severity={alert.type}>
            {alert.message}
          </Alert>
        }
        <TransitionGroup>
          <CSSTransition key={this.props.location.key} classNames="page" timeout={300}>
            <Switch>
              <Route exact path="/home" component={HomePage} />
              <Route exact path="/contact" component={() => <Contact />} />
              <Route exact path="/history" component={() => <History />} />
              <Route exact path="/conditionutilisation" component={() => <ConditionUtilisation  />} />
              <Route exact path="/howitworks" component={() => <HowItWorks />} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/register" component={RegisterPage} />
              <PrivateRoute exact path="/userprofile/:userId" component={UserProfileWithId} />
              <Route exact path="/demandresetpassword" component={DemandResetPasswordPage} />
              <Route exact path="/resetpws/:token" component={ResetPasswordWithToken} />
              <PrivateRoute exact path="/addtrip" component={AddTripPage} />
              <PrivateRoute exact path="/adddelivery" component={AddDeliveryPage} />
              <PrivateRoute exact path="/mytrips" component={MyTripsPage} />
              <PrivateRoute exact path="/mytrips/:tripId" component={MyTripWithId} />
              <PrivateRoute exact path="/mydeliveries" component={MyDeliveriesPage} />
              <PrivateRoute exact path="/mydeliveries/:deliveryId" component={MyDeliveryWithId} />
              <PrivateRoute exact path="/profil" component={currentUserProfilePage} />
              <Route exact path="/searchannouncements" component={SearchAnnouncementsPage} />
              <Route exact path="/announcements" component={AnnouncementsPage} />
              <Route exact path="/announcements/:announcementId" component={AnnouncementWithId} />
              <PrivateRoute exact path="/messages" component={MessagesPage} />
              <PrivateRoute exact path="/messages/:conversationId" component={MessageWithId} />
              <Redirect to="/home" />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        <Footer />
      </div>
    );
  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
