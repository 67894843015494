export const actionType = {

    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    LOGOUT       : 'USER_LOGOUT',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',
    
    UPDATEUSER_SUCCESS: 'UPDATEUSER_SUCCESS',
    UPDATEUSER_REQUEST: 'UPDATEUSER_REQUEST',
    UPDATEUSER_FAILURE: 'UPDATEUSER_FAILURE',


    SEND_EMAIL_RESETPASSWORD : 'SEND_EMAIL_RESETPASSWORD',
    UPDATE_PASSWORD : 'UPDATE_PASSWORD',

    

    
};
