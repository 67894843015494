import React from 'react';
import { Button, Col, Row} from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Loading } from '../common/LoadingComponent';




const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
//const passwordsMatch = (vals) => vals.password === vals.confirmpassword;



function ResetPassword(props) {  

    function handleResetPassword(values) {
        props.updatePassword(props.token,values.password)   
    }
    if(props.isUpdatingPassword){
        return (
            <div class="container">
            <div className="container text-center">
                <br/>
    <h3> Réinitialisez votre mot de passe</h3>   
          </div> 
            <div className="Login text-center">
            <Loading />
            </div>
        </div>
        );
    }
    else 
    return (
        <div class="container">
                <div className="container text-center">
                    <br/>
        <h3> Réinitialisez votre mot de passe</h3>   
              </div> 
                <div className="Login text-center">
                <LocalForm  onSubmit={(values) => handleResetPassword(values)} 
                validators={{
                    '': {
                      passwordsMatch: (vals) => vals.password === vals.confirmpassword,
                    },
                    password : {required,maxLength: maxLength(30),minLength: minLength(8)},
                    confirmpassword : {required,maxLength: maxLength(30),minLength: minLength(8)},

                  }}>
                    <Row className="form-group">
                        <Col>
                            <Control.password model=".password" id="password" name="password" innerRef={(input) => this.password = input}
                                placeholder="Nouveau mot de passe (Entre 8 et 30 caractères) "
                                className="form-control"
                                validators={{
                                    required,maxLength: maxLength(30), minLength: minLength(8)
                                }} />
                            <Errors
                                className="errors"
                                model=".password"
                                show="touched"
                                messages={{
                                    required: ' Veuillez saisir votre mot de passe.',
                                    maxLength: '  30 caractères maximum',
                                    minLength: ' 8 caractères minimum'
                                }}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row className="form-group">
                        <Col>
                            <Control.password   model=".confirmpassword" id="confirmpassword" name="confirmpassword" innerRef={(input) => this.confirmpassword = input}
                                placeholder=" Confirmation du nouveau Mot de passe"
                                className="form-control"
                                validators={{
                                    required,maxLength: maxLength(30), minLength: minLength(8)
                                }} />
                            <Errors
                                className="errors"
                                model=".confirmpassword"
                                show="touched"
                                messages={{
                                    required: ' Veuillez saisir votre mot de passe.',
                                    maxLength: '  30 caractères maximum',
                                    minLength: ' 8 caractères minimum',
                                    passwordsMatch : ' Les mots de passe ne sont pas identiques !'
                                }}
                            />
                            <Errors model="resetPasswordForm" 
                            className="errors"
                            messages={{
                                passwordsMatch : ' Les mots de passe ne sont pas identiques !'
                            }}/>
                        </Col>
                    </Row>
                    <br/>
                    <Button  type="submit" value="submit" color="primary"><strong>Changer le mot de passe</strong></Button>               
                </LocalForm>  
                <br/><br/><br/>                
                </div>
        </div>
        
        
        
    );
}

export default ResetPassword;