import React from 'react';
import { Nav, NavItem,Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import NotificationMessage from './NotificationMessageComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad, faSearch, faCube, faUserCircle, faComments } from '@fortawesome/free-solid-svg-icons';
import { currentUser } from '../../redux/helpers/currentUser';

const tabs = [{
    route: "/adddelivery",
    icon: faCube,
    label: "Envoyer"
}, {
    route: "/addtrip",
    icon: faRoad,
    label: "Transporter"
}, {
    route: "/searchannouncements",
    icon: faSearch,
    label: "rechercher"
}, {
    route: "/messages",
    icon: faComments,
    label: "Messages"
}, {
    route: "/profil",
    icon: faUserCircle,
    label: "Profil"
}]
const Navigation = (props) => {
    if (window.location.pathname.split('/')[1] === 'messages' && window.location.pathname.split('/')[2] != null)
        return (<div></div>);
    else {
        return (
            <div >
                {/* Bottom Tab Navigator*/}
                <nav className="navbar fixed-bottom navbar-light bottom-tab-nav d-block d-sm-none" role="navigation">
                    <Nav className="w-100">
                        <div className="d-flex flex-row justify-content-around w-100">
                            {
                                tabs.map((tab, index) => (
                                    <NavItem key={`tab-${index}`}>
                                        <NavLink to={tab.route} className="nav-link bottom-nav-link" activeClassName="active">
                                            <div className="row d-flex flex-column justify-content-center align-items-center">
                                            {tab.label === "Messages"  ?
                                                <span  style={{
                                                    position:"absolute",
                                                    fontSize: "0.8em",
                                                    color: "white",
                                                    bottom: "2.5em",
                                                    paddingLeft:"1.5em"
                                                    
                                                }}> <NotificationMessage isLoading={props.isLoading} notifications={props.notifications} /> </span> : ''}
                                            {tab.label === "Profil" && currentUser().hasOwnProperty('id') && !(currentUser().numberMobile  && currentUser().birthDate )  ?
                                                <span  style={{
                                                    position:"absolute",
                                                    fontSize: "1em",
                                                    color: "white",
                                                    bottom: "2.1em",
                                                    paddingLeft:"1.1em"
                                                    
                                                    
                                                }}> <Badge color="danger" pill>!</Badge> </span> : ''}    
                                                <FontAwesomeIcon size="lg" icon={tab.icon} />
                                                
                                                <div className="bottom-tab-label">{tab.label}</div>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                ))
                            }
                        </div>
                    </Nav>
                </nav>
            </div>
        )
    };
}
export default Navigation;