import React,{useState} from 'react';
import { CardBody, CardTitle, CardText } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { Loading } from '../common/LoadingComponent';
import { Col, Row } from 'reactstrap';
import ConfirmDialog from '../common/ConfirmActionComponent';

const RenderDescription = ({ description }) => {
    if (description)
        return (
            <React.Fragment>
                <hr />
                <CardText><strong>Description : </strong>  {description} </CardText>
            </React.Fragment>

        );
    else
        return (
            <div></div>
        )
}
function RenderDelivery({ delivery }) {
    const departure = delivery.departure.split('--');
    const destination = delivery.destination.split('--');
    return (
        <div key={delivery.id} className="mt-3">
            <div >
                <Col>
                    <Row>
                        <CardBody>
                            <CardTitle heading>
                                À livrer avant : le {new Intl.DateTimeFormat('fr-FR', { weekday: 'long', month: 'long', day: '2-digit' }).format(delivery.dateDeparture)}

                            </CardTitle>
                            <CardText > <i className="fa fa-map-marker"></i> <strong>{departure[0]}</strong> - {departure[1]}</CardText>
                            <CardText > <i className="fa fa-map-marker"></i> <strong>{destination[0]}</strong> - {destination[1]}</CardText>
                            <hr />
                            <CardText><strong>Colis à transporter : </strong>  {delivery.objectAccepted} </CardText>
                            <CardText><strong>Prix : </strong>  {delivery.price} €</CardText>
                            <RenderDescription description={delivery.description} />
                        </CardBody>
                    </Row>
                </Col>
            </div>
        </div>
    )
}


const MyDeliveryDetail = (props) => {
    const [confirmOpen, setConfirmOpen] = useState(false);

      
    function handleDeleteAnnouncement() {
        console.log(props.delivery.id)
        props.deleteDelivery(props.delivery.id);
    }  

    if (props.isLoading) {
        return (
            <div className="container">
                <div className="row">
                    <Loading />
                </div>
            </div>
        );
    } else if (props.errMess) {
        return (
            <div className="container">
                <div className="row">
                    <h4>{props.errMess}</h4>
                </div>
            </div>
        );
    }
    else if (props.delivery != null) {
        return (
            <div className="container">
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }} >
                        <h3 className="text-center mt-3">Ma demande livraison</h3>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <br />
                        <RenderDelivery delivery={props.delivery} />
                        <Col className="text-center">
                        <Button color="secondary" variant="contained" onClick={setConfirmOpen}>
                                Annuler votre demande
                                    </Button>
                            <ConfirmDialog
                                title="Supprimer Annonce ?"
                                open={confirmOpen}
                                setOpen={setConfirmOpen}
                                onConfirm={handleDeleteAnnouncement}
                            >
                                Vous êtes sur le point d'annuler votre demande de livraison.
                                 Celui-ci sera supprimé et les voyageurs ne pourront plus transporter votre colis.
                        </ConfirmDialog>

                        </Col>
                    </Col>
                </Row>
                <br /><br /><br />
            </div>
        );
    } else {
        return (<div></div>);
    }
}


export default MyDeliveryDetail;


