import { actionType as mydeliveriesActionType } from './actionType/myDeliveries.actionTypes';
import { baseUrl } from '../shared/baseUrl';
import { authHeader } from '../helpers/auth-header';
import { currentUser } from '../helpers/currentUser';
import { error as errorAlert, success as successAlert } from './alert.actionCreator';
import { history } from '../helpers/history';




export const fetchMyDeliveries = () => (dispatch) => {
    if(localStorage.getItem('user')){
    dispatch(myDeliveriesRequest());
    return fetch(`${baseUrl}/service/mydeliveries?code=${currentUser().id}`, {
        method: 'GET',
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json' },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error('Error ' + response.status + ':' + response.error);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(response => dispatch(myDeliveriesSuccess(response)))
        .catch(error =>  dispatch(myDeliveriesFailure(error.message)))

    }

}

export const deleteDelivery = (idAnnouncement) => (dispatch) => {
    return fetch(`${baseUrl}/service/deteleannouncement?idUser=${currentUser().id}&idAnnouncement=${idAnnouncement}`, {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => {
            history.push('/mydeliveries')
            dispatch(fetchMyDeliveries());
            dispatch(successAlert("votre annonce a été supprimer avec succès"));
        })
        .catch(error => {
            dispatch(errorAlert("INTERNAL ERROR"));
        })

}



export const myDeliveriesRequest = () => ({
    type: mydeliveriesActionType.MYDELIVERIES_REQUEST
});

/* a action*/
export const myDeliveriesFailure = (errmess) => ({
    type: mydeliveriesActionType.MYDELIVERIES_FAILURE,
    payload: errmess
});

/* a action*/
export const myDeliveriesSuccess = (myDeliveries) => ({
    type: mydeliveriesActionType.MYDELIVERIES_SUCCESS,
    payload: myDeliveries
});

export const postDelivery = (delivery) => ({

    type: mydeliveriesActionType.ADD_DELIVERY,
    payload: delivery
});




