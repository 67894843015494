import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Control, LocalForm, actions } from 'react-redux-form';

const required = (val) => val && val.length;

class SendMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            valueMessage: null
        };
        this.sendMessage = this.sendMessage.bind(this);
        this.handleChangeMessage=this.handleChangeMessage.bind(this);
    }

    attachDispatch(dispatch) {
        this.formDispatch = dispatch;
    }


    handleChangeMessage(values) {
        this.setState({
            valueMessage : values.target.value
        })

    }
    sendMessage(values) {
        this.props.sendMessage(values.message)
        this.formDispatch(actions.reset('messageForm'));

    }

    render() {
    

    
        return (
         
                <Col  sm="12" md={{ size: 6, offset: 3 }}  >
                    <LocalForm model="messageForm"
                        onSubmit={(values) => this.sendMessage(values)}
                        getDispatch={(dispatch) => this.attachDispatch(dispatch)}
                        initialState={{ message: '' }}>
                             <Row className="form-group">
                                 <Col >
                                <Control.text model=".message" id="message" name="message" innerRef={(input) => this.messsage = input}
                                    placeholder="Tapez votre message ..."
                                    className="form-control"
                                    onChange={this.handleChangeMessage}
                                    validators={{
                                        required
                                    }} />
                                    </Col>
                                   { this.state.valueMessage ? <Col xs={2} className="pt-1">
                                       <Button type="submit" value="submit" color="primary"><span class="fa fa-send fa-lg"></span></Button>
                                  </Col> : <Col xs={2}></Col> }
                                 </Row>   
                    </LocalForm>
                </Col>
        );
    }




}


export default SendMessage;









