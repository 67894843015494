import React, { Component } from 'react';
import { Card, Col, Row, CardTitle, CardText, ListGroup, ListGroupItem } from 'reactstrap';
import { Loading } from '../common/LoadingComponent';
import { Link } from 'react-router-dom';
import { currentUser } from '../../redux/helpers/currentUser';
import SendMessage from './SendMessageComponent';


var otherUser = null;
var announcement = null;
var messages = [];
var messagesTemplate = null;

class MessageDetail extends Component {

    constructor(props) {
        super(props);
    }

    handleSendMessage = (value) => {
        const newMessage = {
            message: value,
            idAnnouncement: announcement.id,
            idUserSender: currentUser().id,
            idUserReceiver: otherUser.id,
            dateTime: Date.now()
        }

        messages.push(newMessage)
        this.props.sendMessage(newMessage)
    }

    render() {
        
        const RenderAnnouncement = ({ announcement }) => {
            const departure = announcement.departure.split('--');
            const destination = announcement.destination.split('--');
            const departDate = new Intl.DateTimeFormat('fr-FR', { weekday: 'short', month: 'short', day: '2-digit' }).format(announcement.dateDeparture);

            return (
                <ListGroupItem key={announcement.id} tag="a" action>
                    <Link to={{
                        pathname: `/announcements/${announcement.id}`,
                        state: { from: window.location.pathname }
                    }} className="inheritLinkColor">
                        <Row>
                            <Col >
                                <CardTitle heading>
                                    {departDate.charAt(0).toUpperCase() + departDate.slice(1)}
                                </CardTitle>
                                <CardText className="text-muted" > <h6> {departure[0]} ---<i className="fa fa-angle-right fa-lg"></i> {destination[0]}</h6></CardText>
                            </Col>

                        </Row>
                    </Link>
                </ListGroupItem>
            )

        }



        const RenderUser = ({ user }) => {

            return (
                <ListGroupItem key={user.id} tag="a" action>
                    <Link to={{
                        pathname: `/userprofile/${user.id}`,
                        state: { from: window.location.pathname }
                    }} className="inheritLinkColor">
                        <CardText >
                            <h5> <img
                                className="user-avatar rounded-circle mr-2"
                                src="/assets/images/profil.svg"
                                alt="User Avatar"
                                height="40" width="40"
                            />{" "}
                                <span className="text-dark"> {user.lastName} {user.firstName} </span> </h5>
                        </CardText>
                    </Link>
                </ListGroupItem>
            )

        }
        const RenderMessages = ({ conversation }) => {

            const isMe = currentUser().id === conversation.userSender.id;
            const floatDirection = isMe ? 'right' : 'left';
            const backgroundColor = isMe ? '#283b82' : 'rgb(237, 237, 237)';
            const color = isMe ? 'white' : 'black';
            const borderRadius = isMe ? ' 15px 0px 15px 15px' : '0px 15px 15px 15px';

            const textStyle = {
                float: floatDirection,
                backgroundColor: backgroundColor,
                padding: '6px 10px',
                borderRadius: borderRadius,
                textAlign: 'left',
                color: color
            }
            return (

                <div key={conversation.message.id} className="pt-1">
                    <Card style={textStyle}>
                        <h6>{conversation.message.message}<br />
                            <small className="text-center">
                                {new Intl.DateTimeFormat('fr-FR', { hour: '2-digit', minute: '2-digit' }).format(conversation.message.dateTime)}
                            </small>
                        </h6>

                    </Card>
                </div>
            )
        }
        if (this.props.isLoading) {
            return (
                <div className="container">
                    <div className="row">
                        <Loading />
                    </div>
                </div>
            );
        } else if (this.props.errMess) {
            return (
                <div className="container">
                    <div className="row">
                        <h4>{this.props.errMess}</h4>
                    </div>
                </div>
            );
        }
        else if (this.props.messages != null) {
            const isMe = currentUser().id === this.props.messages[0].userSender.id;
            otherUser = isMe ? this.props.messages[0].userReceiver : this.props.messages[0].userSender;
            announcement = this.props.messages[0].announcement;

            const messages = this.props.messages.map((item) => {
                return (

                    <ListGroup>
                        <RenderMessages conversation={item} />
                    </ListGroup>
                );
            });

            return (
                <div className="container">
                    <div className="modal__header">
                        <Col sm="12" md={{ size: 6, offset: 3 }}  >
                            <ListGroup flush>
                                <RenderAnnouncement announcement={announcement} />
                                <RenderUser user={otherUser} />
                            </ListGroup>

                        </Col>
                    </div>
                    <div className="container modal__content">
                        <Col sm="12" md={{ size: 6, offset: 3 }}  >
                            {messages}
                            <br/>
                        </Col>
                    </div>
                    <div className="modal__footer fixed-bottom container">
                        <SendMessage sendMessage={this.handleSendMessage} />
                    </div>
                </div>
            );
        } else if (this.props.messagesFirstContact != null) {

            const textStyle = {
                float: 'right',
                backgroundColor: '#283b82',
                padding: '6px 10px',
                borderRadius: ' 15px 0px 15px 15px',
                textAlign: 'left',
                color: 'white'
            }
            if (messages !== []) {
                messagesTemplate = messages.map((item) => {
                    return (
                        <ListGroup flush>
                            <div className="pt-1">
                                <Card style={textStyle}>
                                    <h6>{item.message}<br />
                                        <small className="text-center">
                                            {new Intl.DateTimeFormat('fr-FR', { hour: '2-digit', minute: '2-digit' }).format(item.dateTime)}
                                        </small>
                                    </h6>

                                </Card>
                            </div>
                        </ListGroup>
                    );
                });
            }
            otherUser = this.props.messagesFirstContact.user;
            announcement = this.props.messagesFirstContact.announcement
            return (
                <div className="container">
                <div className="modal__header">
                    <Col sm="12" md={{ size: 6, offset: 3 }}  >
                        <ListGroup flush>
                            <RenderAnnouncement announcement={announcement} />
                            <RenderUser user={otherUser} />
                        </ListGroup>

                    </Col>
                </div>
                <div className="container modal__content">
                    <Col sm="12" md={{ size: 6, offset: 3 }}  >
                        {messagesTemplate}
                    </Col>
                </div>
                <div className="modal__footer fixed-bottom container">
                    <SendMessage sendMessage={this.handleSendMessage} />
                </div>
            </div>
            );
        }
        else
            return (<div></div>)
    }
}


export default MessageDetail;


