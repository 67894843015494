import { actionType as addDeliveryActionType } from './actionType/addDelivery.actionTypes';
import { error as errorAlert, success as successAlert } from './alert.actionCreator';
import {  postDelivery } from './myDeliveries.actionCreator';
import { baseUrl } from '../shared/baseUrl';
import { authHeader } from '../helpers/auth-header';
import { currentUser } from '../helpers/currentUser';
import { history } from '../helpers/history';




export const addDelivery = (delivery) => (dispatch) => {

    const newdelivery = {
        departure:delivery.departure,
        destination : delivery.destination,
        dateDeparture : Date.parse(delivery.dateDeparture),
        objectAccepted : delivery.objectAccepted,
        price : delivery.price,
        description : delivery.description,
        idUser : currentUser().id
    }
    dispatch(addDeliveryRequest());
    return fetch(baseUrl + '/service/addannouncement', {
        method: 'POST',
        body: JSON.stringify(newdelivery),
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json' },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(response => {
                dispatch(addDeliverySuccess(response));
                dispatch(postDelivery(response));
                history.push('/mydeliveries')
                dispatch(successAlert("Votre annonce est en ligne, des voyageurs peuvent vous contacter  pour  transporter votre Colis "));
                   
        })
        .catch(error => {
            dispatch(addDeliveryFailure(error));
            dispatch(errorAlert(error.toString()));
        })

}


export const addDeliveryRequest = () => ({
    type: addDeliveryActionType.ADDDELIVERY_REQUEST
});

/* a action*/
export const addDeliveryFailure = (errmess) => ({
    type: addDeliveryActionType.ADDDELIVERY_FAILURE,
    payload: errmess
});

/* a action*/
export const addDeliverySuccess = (deliveryInserted) => ({
    type: addDeliveryActionType.ADDDELIVERY_SUCCESS,
    payload: deliveryInserted
});
