import { actionType as notificationsActionType } from './actionType/notification.actionTypes';
import { baseUrl } from '../shared/baseUrl';
import { authHeader } from '../helpers/auth-header';
import { currentUser } from '../helpers/currentUser';




export const fetchNotifications = () => (dispatch) => {
    if(localStorage.getItem('user')){
    dispatch(notificationsRequest());
    return fetch(`${baseUrl}/service/notifications?iduser=${currentUser().id}`, {
        method: 'GET',
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json' },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error('Error ' + response.status + ':' + response.error);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(response => dispatch(notificationsSuccess(response)))
        .catch(error =>  dispatch(notificationsFailure(error.message)))

     }

}


export const notificationsRequest = () => ({
    type: notificationsActionType.NOTIFICATION_REQUEST
});

/* a action*/
export const notificationsFailure = (errmess) => ({
    type: notificationsActionType.NOTIFICATION_FAILURE,
    payload: errmess
});

/* a action*/
export const notificationsSuccess = (notifications) => ({
    type: notificationsActionType.NOTIFICATION_SUCCESS,
    payload: notifications
});




export const updateNotification = (idAnnouncement,idUser,IdUserSender) => (dispatch) => {
    const notificationToUpdate = {
         idAnnouncement:idAnnouncement,
	     idUser:idUser,
         idUserSender:IdUserSender
    }
    return fetch(`${baseUrl}service/updatenotification`, {
        method: 'POST',
        body: JSON.stringify(notificationToUpdate),
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json' },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response =>{
             dispatch(updateNotificationSuccess());
             dispatch(fetchNotifications());
        })                    
   
        .catch(error => {
            console.log(error)
        })
    

}


export const updateNotificationSuccess = () => ({
    type: notificationsActionType.UPDATE_NOTIFICATION_SUCCESS
});
