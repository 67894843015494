export const InitialRegister = {
    firstName: '',
    lastName: '',
    numberMobile: '',
    email: '',
    password :'',
    birthDate: ''
};

export const InitialTrip = {
  departure:'',
  destination : '',
  destination1: '',
  destination2: '',
  destination3: '',
  dateDeparture:'',
  dateDestination : '',
  meansOfTransport:'',
  objectAccepted : '',
  price : '',
  description : null
}

export const InitialDelivery = {
  departure:'',
  destination : '',
  dateDeparture : '',
  objectAccepted : '',
  price : '',
  description : null
}

export const InitialeSearchTrips = {
    departure:'',
    destination : '',
    dateDeparture:'',
}

export const InitialUpdateUser = {
  firstName: '',
  lastName: '',
  numberMobile: '',
  birthDate: ''
};


