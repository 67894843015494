import { combineReducers } from 'redux';
import { authentification } from './user.reducer';/*reducer*/
import { alert } from './alert.reducer';/*reducer*/
import { registration } from './register.reducer';/*reducer*/
import { addTrip } from './addTrip.reducer';/*reducer*/
import { addDelivery } from './addDelivery.reducer';/*reducer*/
import { myTrips } from './myTrips.reducer';/*reducer*/
import { myDeliveries } from './myDeliveries.reducer';/*reducer*/
import { messages } from './message.reducer';/*reducer*/
import { notifications } from './notification.reducer';/*reducer*/
import { searchTrips } from './searchTrips.reducer';/*reducer*/
import { createForms } from 'react-redux-form';
import { InitialRegister, InitialTrip, InitialeSearchTrips, InitialDelivery, InitialUpdateUser} from '../forms';


const appReducer = combineReducers({
    user : authentification,
    alert : alert,
    registerUser : registration,
    myTrips : myTrips,
    myDeliveries : myDeliveries,
    addTrip : addTrip,
    addDelivery : addDelivery,
    announcements : searchTrips,
    messages : messages,
    notifications:notifications,
    ...createForms({
        registerForm : InitialRegister,
        addTripForm : InitialTrip,
        addDeliveryForm : InitialDelivery,
        searchTripsForm : InitialeSearchTrips,
        updateUserForm  : InitialUpdateUser
    })
})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT' && !localStorage.getItem('user')) {
      state = undefined
    }
  
    return appReducer(state, action);
  }

  export default rootReducer;