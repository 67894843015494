import React,{useEffect} from 'react';
import { Button, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle,Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FadeTransform } from 'react-animation-components';



function Contact(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
       <div>
             <Jumbotron>
                 <div className="container">
                     <div className="row row-header">
                         <div className="col-12 col-sm-6">
                             <h1>Contact</h1>
                         </div>
                        
                     </div>
                 </div>
             </Jumbotron>
             
             <div className="container row row-content">
              <div className="col-12">
              <h3>Email/Téléphone</h3>
              </div>
              <div className="col-12 col-sm-4 offset-sm-1">
                      <h5>Adresse</h5>
                      <address>
                      14 rue curial<br />
                      Paris,Île-de-France<br />
                      France<br />
                      <i className="fa fa-phone"></i>: +33 7 53 06 01 90<br />
                      <i className="fa fa-envelope"></i>: <a href="mailto:contact@eudeliv.com">contact@eudeliv.com</a>
                      </address>
              </div>
              <div className="col-12 col-sm-6 offset-sm-1">
              <div className="btn-group" role="group">
                      <a role="button" className="btn btn-primary" href="tel:+33753060190"><i className="fa fa-phone"></i> Appeler</a>
                      <a role="button" className="btn btn-primary" href="mailto:contact@eudeliv.com"><i className="fa fa-envelope-o"></i> Email</a>
                      
                  </div>
              </div>
          </div>
        </div>
    );
}

export default Contact;