import React, { Component } from 'react';
import { Control, Form, Errors } from 'react-redux-form';
import { Button, Col, Row} from 'reactstrap';
import { Loading } from '../common/LoadingComponent';
import { Fade } from 'react-animation-components';
import { Progress } from 'reactstrap';
import LocationSearchInput from '../common/LocationSearchCities';
import DatePicker from "react-datepicker";
import fr from 'date-fns/locale/fr'
import "react-datepicker/dist/react-datepicker.css"



const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));
class AddTrip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep:0,
            departureDate: null,
            destinationDate :null
        };
        this.handleAddTrip = this.handleAddTrip.bind(this);
        this.nextStepForm = this.nextStepForm.bind(this);
        this.handleDestinationDateChange = this.handleDestinationDateChange.bind(this);
        this.handleDepartureDateChange = this.handleDepartureDateChange.bind(this);
        this.addStopOverForm = this.addStopOverForm.bind(this);
        this.props.resetAddTripForm();

    }



    handleDestinationDateChange(data) {
        this.setState({
            destinationDate : data
        });
        this.nextStepForm();
    
    }

    handleDepartureDateChange(data) {
        
        this.setState({
            departureDate : data
        });
        this.nextStepForm();
    }

    nextStepForm() {
        this.setState({
            currentStep: this.state.currentStep + 1
        });
    }

    handleAddTrip(values) {
        this.props.addTrip(values);
        this.props.resetAddTripForm();
    }

    addStopOverForm () {
            return (
                <>
                    <Row className="form-group">
                        <Col md={10}>
                            <LocationSearchInput  model=".destination1" id="destination1" name="destination1"
                                placeholder="Première ville (optionnelle)"
                                formName="addTripForm"
                                autoFocus="true"
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col md={10}>
                            <LocationSearchInput  model=".destination2" id="destination2" name="destination2"
                                placeholder="Deuxième ville (optionnelle)"
                                formName="addTripForm"
                            />
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col md={10}>
                            <LocationSearchInput  model=".destination3" id="destination3" name="destination3"
                                placeholder="Troisième ville (optionnelle)"
                                formName="addTripForm"
                            />
                        </Col>
                    </Row>
                </>
            )
        }

    render() {
        const RenderNextButton = () => {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 9, offset: 0 }}>
                        <Control.button
                            className="btn btn-primary"
                            onClick={this.nextStepForm}
                            model="addTripForm"
                            disabled={{ valid: false }} >
                            Continuer
                    </Control.button>
                    </Col>
                </Row>
            );

        }


        const RenderDeparture = ({ currentStep }) => {
            if (currentStep === 0)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre ville de départ :</h4>
                            </Col>
                        </Row>
                        <br /><br />

                        <Row className="form-group">
                            <Col md={10}>
                                <LocationSearchInput model=".departure" id="departure" name="departure"
                                    placeholder="Ville de Départ"
                                    onClickSuggestionItem={this.nextStepForm}
                                    formName="addTripForm"
                                />
                            </Col>
                        </Row>

                    </React.Fragment >
                );
            else
                return (
                    <div></div >
                )
        }
        const RenderDestination = ({ currentStep }) => {
            if (currentStep === 1)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre ville de destination :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">
                            <Col md={10}>
                            
                                <LocationSearchInput model=".destination" id="destination" name="destination"
                                    placeholder="Ville de Destination"
                                    onClickSuggestionItem={this.nextStepForm}
                                    formName="addTripForm"
                                    autoFocus="true"
                                    
                                />
                            </Col>
                        </Row>
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }
        //Add stop over
        const RenderStopOver = ({ currentStep }) => {
            if (currentStep === 2)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="10" md={{ size: 9, offset: 2 }}>
                                <br />
                                <h4>Souhaitez-vous ajouter d'autres villes par lesquelles vous allez passer durant votre trajet ?</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Button className="stop-over-btn btn btn-outline-primary btn-lg btn-block" onClick={() => this.setState({addStopOverForm: true}) } >Ajouter</Button>
                        <br /><br />
                        {this.state.addStopOverForm ? this.addStopOverForm() : null}
                        <br /><br />
                    
                        <Row className="text-center"> 
                            <Col> 
                                <Button className="btn btn-primary" onClick={this.nextStepForm}>Continuer</Button>
                            </Col>
                        </Row>
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }

        const RenderDateDeparture = ({ currentStep }) => {
            if (currentStep === 3)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre date de départ :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group text-center">
                            <Col>
                                <Control type="date" model=".dateDeparture" id="dateDeparture" name="dateDeparture"
                                    className="form-control"
                                    component={DatePicker}
                                    onSelect={this.handleDepartureDateChange}
                                    minDate={new Date()}
                                    selected={this.state.departureDate} 
                                    showTimeelect
                                    dateFormat=" dd-MM-yyyy"
                                    placeholderText="date de départ"
                                    locale={fr}
                                    inline   
                                    monthsShown={2}                           
                                />                                 
                            </Col>
                        </Row>
                        <br />
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }
        const RenderDateDestination = ({ currentStep }) => {
            if (currentStep === 4)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre date d'arrivé :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group text-center">
                            <Col>

                                <Control type="date" model=".dateDestination" id="dateDestination" name="dateDestination"
                                    inline
                                    className="form-control"
                                    component={DatePicker}
                                    onSelect={this.handleDestinationDateChange}
                                    minDate={this.state.departureDate} 
                                    showTimeelect
                                    selected={this.state.destinationDate}
                                    dateFormat=" dd-MM-yyyy"
                                    placeholderText="Date de Destination"
                                    monthsShown={2}
                                    locale={fr}
                                />
                            </Col>
                        </Row>
                        <br />
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }

        const RenderMeansOfTransport = ({ currentStep }) => {
            if (currentStep === 5)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre moyen de transport :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">
                            <Col md={10}>
                                <Control.select model=".meansOfTransport" name="meansOfTransport"
                                    validators={{
                                        required
                                    }}>
                                    <option value='' disabled>Moyen de Transport </option>
                                    <option>Avion</option>
                                    <option>Voiture</option>
                                    <option>Train</option>
                                    <option>Bus</option>
                                </Control.select>
                            </Col>
                        </Row>
                        <br />
                        <RenderNextButton />
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }
        const RenderObjectedAccepted = ({ currentStep }) => {
            if (currentStep === 6)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Les colis  acceptées à transporter au cour du trajet :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">

                            <Col md={10}>
                                <Control.select model=".objectAccepted" name="objectAccepted"
                                    autoFocus="true"
                                    validators={{
                                        required,minLength: minLength(2)
                                    }}>
                                    <option value='' disabled>Colis  acceptées </option>
                                    <option>taille S ( Petit )</option>
                                    <option>taille M ( Moyen )</option>
                                    <option>taille L ( Grand )</option>
                                    <option>taille XL ( très Grand ) </option>
                                </Control.select>
                            </Col>
                        </Row>
                        <br />
                        <RenderNextButton />
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }
        const RenderPrice = ({ currentStep }) => {
            if (currentStep === 7)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre prix  proposé :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">
                            <Col md={10}>
                                <Control.text model=".price" id="price" name="price"
                                    placeholder="Prix (en euro)"
                                    className="form-control"
                                    autoFocus="true"
                                    validators={{
                                        required, isNumber, maxLength: maxLength(5)
                                    }}
                                />
                                <Errors
                                    className="errors"
                                    model=".price"
                                    show="touched"
                                    messages={{
                                        required: ' Veuillez saisir votre prix.',
                                        isNumber: ' Veuillez saisir que des chiffres.',
                                        maxLength: '  5 caractères maximum.'
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <RenderNextButton />
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }
        const RenderDescription = ({ currentStep }) => {
            if (currentStep === 8)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Une description pour votre trajet :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">
                            <Col md={10}>
                                <Control.textarea model=".description" id="description" name="description"
                                    rows="4"
                                    placeholder="ex: je fais ce trajet le lundi de chaque semaine"
                                    className="form-control"
                                    autoFocus="true"
                                    validators={{
                                        maxLength: maxLength(999)

                                    }}
                                />
                                <Errors
                                    className="errors"
                                    model=".description"
                                    show="touched"
                                    messages={{
                                        maxLength: '  999 caractères maximum.'
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row className="form-group text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <Control.button className="btn btn-primary" disabled={{ valid: false }} model="addTripForm" type="submit" color="primary">
                                    Publier
                                    </Control.button>
                            </Col>
                        </Row>
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }

        const RenderAddTrip = ({ isAddingTrip }) => {
            if (isAddingTrip) {
                return (
                    <Row className="text-center">
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Loading />
                        </Col>
                    </Row>

                );
            }
            else {
                return (
                    <React.Fragment>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                                <Form name="addTripForm" model="addTripForm" onSubmit={(values) => this.handleAddTrip(values)}>

                                    <Fade in>
                                        <RenderDeparture currentStep={this.state.currentStep} />
                                    </Fade>
                                    <Fade in>
                                        <RenderDestination currentStep={this.state.currentStep} />
                                    </Fade>   
                                    <Fade in>
                                        <RenderStopOver currentStep={this.state.currentStep} />
                                    </Fade> 
                                    <Fade in>                            
                                        <RenderDateDeparture currentStep={this.state.currentStep} />
                                    </Fade>
                                    <Fade in >
                                        <RenderDateDestination currentStep={this.state.currentStep} />
                                    </Fade>
                                    <Fade in>
                                        <RenderMeansOfTransport currentStep={this.state.currentStep} />
                                    </Fade>
                                    <Fade in>
                                        <RenderObjectedAccepted currentStep={this.state.currentStep} />
                                    </Fade>
                                    <Fade in>
                                        <RenderPrice currentStep={this.state.currentStep} />
                                    </Fade>
                                    <Fade in>
                                        <RenderDescription currentStep={this.state.currentStep} />
                                    </Fade>
                                </Form>
                            </Col>
                            <br/><br/><br/><br/>
                            </React.Fragment>
                       
 
                );
            }
        }

        //   const register = this.props.forms.register;
        const RenderProgress = ({ responseAddTrip }) => {
            var progress = (this.state.currentStep !== 8) ? this.state.currentStep * 15 : 100;
            if (responseAddTrip)
                return (
                 
                    <Row >
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Progress value={progress} />
                        </Col>
                    </Row>
                );
            else
                return (
                    <div></div>
                );
        }

        return (
            <div className="container">
                <br />
                <RenderProgress responseAddTrip={this.props.responseAddTrip} />
                <RenderAddTrip isAddingTrip={this.props.isAddingTrip}/>
            </div>
        );

    }

}

export default AddTrip;