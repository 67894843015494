import React, { Component } from 'react';
import { Control, Form, Errors } from 'react-redux-form';
import { Col, Row} from 'reactstrap';
import { Loading } from '../common/LoadingComponent';
import { Fade } from 'react-animation-components';
import { Progress } from 'reactstrap';
import LocationSearchInput from '../common/LocationSearchCities';
import DatePicker from "react-datepicker";
import fr from 'date-fns/locale/fr'
import "react-datepicker/dist/react-datepicker.css"


const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));
class AddDelivery extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            currentStep:0,
            destinationDate :null
        };
        this.handleAddDelivery = this.handleAddDelivery.bind(this);
        this.nextStepForm = this.nextStepForm.bind(this);
        this.handleDestinationDateChange = this.handleDestinationDateChange.bind(this);
        this.props.resetAddDeliveryForm();

    }


   
    handleDestinationDateChange(data) {
        this.setState({
            destinationDate : data
        });
        this.nextStepForm();
    
    }


    nextStepForm() {
        this.setState({
            currentStep: this.state.currentStep + 1
        });
    }

    handleAddDelivery(values) {
        this.props.addDelivery(values);
        this.props.resetAddDeliveryForm();
    }

    render() {
        const RenderNextButton = () => {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 9, offset: 0 }}>
                        <Control.button
                            className="btn btn-primary"
                            onClick={this.nextStepForm}
                            model="addDeliveryForm"
                            disabled={{ valid: false }} >
                            Continuer
                     </Control.button>
                    </Col>
                </Row>
            );

        }

        const RenderDeparture = ({ currentStep }) => {
            if (currentStep === 0)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre ville de départ :</h4>
                            </Col>
                        </Row>
                        <br /><br />

                        <Row className="form-group">
                            <Col md={10}>
                                <LocationSearchInput model=".departure" id="departure" name="departure"
                                    placeholder="Ville de Départ"
                                    onClickSuggestionItem={this.nextStepForm}
                                    formName="addDeliveryForm"
                                />
                            </Col>
                        </Row>

                        <br />
                    </React.Fragment >
                );
            else
                return (
                    <div></div >
                )
        }
        const RenderDestination = ({ currentStep }) => {
            if (currentStep === 1)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre ville de destination :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">
                            <Col md={10}>
                            
                                <LocationSearchInput model=".destination" id="destination" name="destination"
                                    placeholder="Ville de Destination"
                                    onClickSuggestionItem={this.nextStepForm}
                                    formName="addDeliveryForm"
                                    autoFocus="true"
                                    
                                />
                            </Col>
                        </Row>
                        <br />
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }
        const RenderDateDeparture = ({ currentStep }) => {
            if (currentStep === 2)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>À livrer avant:</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group text-center">
                            <Col>

                                <Control type="date" model=".dateDeparture" id="dateDeparture" name="dateDeparture"
                                    inline
                                    className="form-control"
                                    component={DatePicker}
                                    onSelect={this.handleDestinationDateChange}
                                    minDate={new Date()}
                                    showTimeelect
                                    selected={this.state.destinationDate}
                                    dateFormat=" dd-MM-yyyy"
                                    placeholderText="Date de Destination"
                                    monthsShown={2}
                                    locale={fr}
                                />
                            </Col>
                        </Row>
                        <br />
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }     
        
        const RenderObjectedAccepted = ({ currentStep }) => {
            if (currentStep === 3)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Type du colis à envoyer :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">

                            <Col md={10}>
                                <Control.select model=".objectAccepted" name="objectAccepted"
                                    autoFocus="true"
                                    validators={{
                                        required,minLength: minLength(2)
                                    }}>
                                    <option value='' disabled>Type du colis </option>
                                    <option>taille S ( Petit )</option>
                                    <option>taille M ( Moyen )</option>
                                    <option>taille L ( Grand )</option>
                                    <option>taille XL ( très Grand ) </option>
                                </Control.select>
                            </Col>
                        </Row>
                        <br />
                        <RenderNextButton />
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }

        const RenderPrice = ({ currentStep }) => {
            if (currentStep === 4)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Votre prix proposé :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">
                            <Col md={10}>
                                <Control.text model=".price" id="price" name="price"
                                    placeholder="Prix (en euro)"
                                    className="form-control"
                                    autoFocus="true"
                                    validators={{
                                        required, isNumber, maxLength: maxLength(5)
                                    }}
                                />
                                <Errors
                                    className="errors"
                                    model=".price"
                                    show="touched"
                                    messages={{
                                        required: ' Veuillez saisir votre prix.',
                                        isNumber: ' Veuillez saisir que des chiffres.',
                                        maxLength: '  5 caractères maximum.'
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <RenderNextButton />
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }
        const RenderDescription = ({ currentStep }) => {
            if (currentStep === 5)
                return (
                    <React.Fragment>
                        <Row className="text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <br />
                                <h4>Une description pour votre colis :</h4>
                            </Col>
                        </Row>
                        <br /><br />
                        <Row className="form-group">
                            <Col md={10}>
                                <Control.textarea model=".description" id="description" name="description"
                                    rows="4"
                                    placeholder="Description de colis (document, médicament...)"
                                    className="form-control"
                                    autoFocus="true"
                                    validators={{
                                        maxLength: maxLength(999)

                                    }}
                                />
                                <Errors
                                    className="errors"
                                    model=".description"
                                    show="touched"
                                    messages={{
                                        maxLength: '  999 caractères maximum.'
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row className="form-group text-center">
                            <Col sm="12" md={{ size: 9, offset: 0 }}>
                                <Control.button className="btn btn-primary" disabled={{ valid: false }} model="addDeliveryForm" type="submit" color="primary">
                                    Publier
                                    </Control.button>
                            </Col>
                        </Row>
                    </React.Fragment>

                );
            else
                return (
                    <div></div>
                )
        }

        const RenderAddDelivery = ({ isAddingDelivery }) => {
            if (isAddingDelivery) {
                return (
                    <Row className="text-center">
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Loading />
                        </Col>
                    </Row>

                );
            }
            else {
                return (
                    <React.Fragment>
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                                <Form name="addDeliveryForm" model="addDeliveryForm" onSubmit={(values) => this.handleAddDelivery(values)}>

                                    <Fade in>
                                        <RenderDeparture currentStep={this.state.currentStep} />
                                    </Fade>
                                    <Fade in>
                                        <RenderDestination currentStep={this.state.currentStep} />
                                    </Fade>   
                                    <Fade in >
                                        <RenderDateDeparture currentStep={this.state.currentStep} />
                                    </Fade>
                                    <Fade in>
                                        <RenderObjectedAccepted currentStep={this.state.currentStep} />
                                    </Fade>
                                    <Fade in>
                                        <RenderPrice currentStep={this.state.currentStep} />
                                    </Fade>
                                    <Fade in>
                                        <RenderDescription currentStep={this.state.currentStep} />
                                    </Fade>



                                </Form>
                            </Col>
                        </Row>
                        <br/><br/><br/><br/>
                    </React.Fragment>
                );
            }
        }

        //   const register = this.props.forms.register;
        const RenderProgress = ({ responseAddDelivery }) => {
            var progress = this.state.currentStep * 20;
            if (responseAddDelivery)
                return (

                    <Row >
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Progress value={progress} />
                        </Col>
                    </Row>
                );
            else
                return (
                    <div></div>
                );
        }

        return (
            <div className="container">
                <br />
                <RenderProgress responseAddDelivery={this.props.responseAddDelivery} />
                <RenderAddDelivery isAddingDelivery={this.props.isAddingDelivery}/>
            </div>
        );

    }

}

export default AddDelivery;