import React , { Component }from 'react';
import { Loading } from '../common/LoadingComponent';
import { Control, LocalForm, Errors} from 'react-redux-form';
import { Col, Row,Label,Button } from 'reactstrap';


const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));



class Profil extends Component  {
    
    constructor(props) {
        super(props);
        this.state = {
            birthDate:null
        };
        
        this.handleUpdateUser = this.handleUpdateUser.bind(this);
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
    }

    handleBirthDateChange(data) {
        this.setState({
            birthDate : data
        });
    
    }
    attachDispatch(dispatch) {
        this.formDispatch = dispatch;
    }
 
    handleUpdateUser(values) {
        console.log(values)
        this.props.updateUser(values);   
    }

    
    render() {
        const  RenderProfile = ({ user })  =>{
    
    
            return (
                <div >
                    <div >
                        <Col>
                        <Col className="border-bottom text-center">
                        <div className="mb-3 mx-auto">
                            <img
                                className="rounded-circle"
                                src="/assets/images/profil.svg"
                                alt={user.firstName}
                                width="87" height="87"
                            />
                        </div>
                        <h4 className="mb-0">{user.firstName} {user.lastName}</h4>
                        
                    </Col>
                    <br/>
                        <LocalForm  onSubmit={(values) => this.handleUpdateUser(values)}
                      //  getDispatch={(dispatch) => this.attachDispatch(dispatch)}
                        initialState={{ 
                        lastName: user.lastName,
                        firstName: user.firstName,
                        birthDate: user.birthDate,
                        numberMobile: user.numberMobile
                         }}
                         >
                        <Row className="form-group">
                        <Label htmlFor="firstName" md={2}>Nom</Label>
                                    
                                    <Col md={10}>
                                        <Control.text  model=".lastName" id="lastName" name="lastName"
                                            placeholder="Nom"
                                            className="form-control"      
                                            validators={{
                                                required, maxLength: maxLength(20)
                                            }} 
                                            />
                                        <Errors
                                            className="errors"
                                            model=".lastName"
                                            show="touched"
                                            messages={{
                                                required: '   Veuillez saisir votre nom.',
                                                maxLength: '  20 caractères maximum.'
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                <Label htmlFor="firstName" md={2}>Prénom</Label>
                                    <Col md={10}>
                                        <Control.text model=".firstName" id="firstName" name="firstName"
                                            placeholder="Prénom"
                                            className="form-control"
                                            validators={{
                                                required, maxLength: maxLength(20)
                                            }}
                                            
                                        />
                                        <Errors
                                            className="errors"
                                            model=".firstName"
                                            show="touched"
                                            messages={{
                                                required: ' Veuillez saisir votre prénom.',
                                                maxLength: ' 20 caractères maximum'
                                            }}
                                        />
                                    </Col>
                                </Row>
                                        
                                <Row className="form-group">
                                <Label htmlFor="birthDate" md={2}>Date de Naissance</Label>
                                    <Col md={10}>
                                        <Control.text type="date" model=".birthDate" id="birthDate" name="birthDate"
                                            placeholder="Date de Naissance"
                                            className="form-control"
                                            maxDate={new Date()}
                                            validators={{
                                                required
                                            }}
                                        />
                                        <Errors
                                            className="errors"
                                            model=".birthDate"
                                            show="touched"
                                            messages={{
                                                required: ' Veuillez saisir votre Date de naissance.'
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                <Label htmlFor="numberMobile" md={2}>Numéro de téléphone</Label>
                                    <Col md={10}>
                                        <Control.text model=".numberMobile" id="numberMobile" name="numberMobile"
                                            placeholder="Numéro de téléphone"
                                            className="form-control"
                                            validators={{
                                                required, minLength: minLength(3), maxLength: maxLength(15), isNumber
                                            }}
                                        />
                                        <Errors
                                            className="errors"
                                            model=".numberMobile"
                                            show="touched"
                                            messages={{
                                                required: ' Veuillez saisir votre numéro de téléphone.',
                                                maxLength: ' 30 caractères maximum',
                                                minLength: ' 3 caractères minimum',
                                                isNumber : ' Veuillez saisir que des chiffres.'
                                            }}
                                        />
                                    </Col>
                                </Row>
                            <br/>
                            <Col className="text-center">
                            <Button type="submit" value="submit" color="primary">
                            Enregistrer
                                    </Button>
                            </Col>
                            
                            
                                  
                        </LocalForm>
                        </Col>
                        <br/><br/><br/><br/>
                    </div>
                    <br/>
                </div>
            )
        }
        if (this.props.isLogin) {
            return (
                <div className="container">
            <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <Loading />
                </Col>
            </Row>
        </div>
            );
        } 
    else if (this.props.user != null) {
        return (
            <div className="container">
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <br />
                        <RenderProfile user={this.props.user.user} />
                    </Col>
                </Row>
            </div>
        );
        } 
     else 
    return (
        <div className="container">
        </div>
    );   


     
    }
}


export default Profil;


