import { actionType as searchAnnouncementsActionType } from './actionType/searchTrips.actionTypes';
import { error as errorAlert} from './alert.actionCreator';
import { baseUrl } from '../shared/baseUrl';
import { history } from '../helpers/history';




export const searchAnnouncements = (departure,destination,dateDeparture) => (dispatch) => {
   let departureCity = departure.split('--')[0];
   let destinationCity = destination.split(',')[destination.split(',').length - 1];
   var today = new Date();
   var dd = String(today.getDate()).padStart(2, '0');
   var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
   var yyyy = today.getFullYear();
   let dateDepartureSearch =  mm + "-" + dd + "-" + yyyy;
   //let dateDepartureSearch =  dateDeparture.getDate() + "-" + (dateDeparture.getMonth() + 1) + "-" + dateDeparture.getFullYear()

    dispatch(searchTripsRequest());
    return fetch(`${baseUrl}announcements?departure=${departureCity}&destination=${destinationCity}&dateDeparture=${dateDepartureSearch}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(response => {
                dispatch(searchTripsSuccess(response));
                history.push('/announcements')
                      
        })
        .catch(error => {
            dispatch(searchTripsFailure(error));
            dispatch(errorAlert(error.toString()));
        })

}


export const searchAnnouncement = () => (dispatch) => {
    
     dispatch(searchTripsRequest());
     return fetch(`${baseUrl}announcement?code=${window.location.pathname.split('/')[2]}`, {
         method: 'GET',
         headers: {
             'Content-Type': 'application/json'
         },
         credentials: 'same-origin'
     })
         .then(response => {
             if (response.ok) {
                 return response;
             }
             /*server response with error like  500*/
             else {
                 var error = new Error(response.status);
                 error.response = response;
                 throw error;
 
             }
         },
             /*server doesn't response*/
             error => {
                 var errmess = new Error(error.message);
                 throw errmess;
             })
         .then(response => response.json())
         .then(response => {
                 dispatch(searchTripsSuccess(response));                     
         })
         .catch(error => {
             dispatch(searchTripsFailure(error));
             dispatch(errorAlert(error.toString()));
         })
 
 }


export const searchTripsRequest = () => ({
    type: searchAnnouncementsActionType.SEARCHTRIPS_REQUEST
});

/* a action*/
export const searchTripsFailure = (errmess) => ({
    type: searchAnnouncementsActionType.SEARCHTRIPS_FAILURE,
    payload: errmess
});

/* a action*/
export const searchTripsSuccess = (announcements) => ({
    type: searchAnnouncementsActionType.SEARCHTRIPS_SUCCESS,
    payload: announcements
});
