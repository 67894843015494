import { actionType as userActionType } from './actionType/user.actionTypes';
import { error as errorAlert, success as successAlert } from './alert.actionCreator';
import { baseUrl } from '../shared/baseUrl';
import { history } from '../helpers/history';
import { fetchMyTrips } from './myTrips.actionCreator';
import { fetchMessages } from './message.actionCreator';
import { fetchMyDeliveries } from './myDeliveries.actionCreator';
import { authHeader } from '../helpers/auth-header';
import { currentUser } from '../helpers/currentUser';


export const autenticate = (email, password,tokenDevice) => (dispatch) => {
    dispatch(loginRequest());
    const user = {
        user: email,
        password: password,
    }
    return fetch(baseUrl + 'login', {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (!response.ok) {
                /*server response with error like  500*/
                throw response;
            }

            else {
                return response.json();
            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        //.then(response => response.json())
        .then(response => {
            localStorage.setItem('user', JSON.stringify(response));
            dispatch(loginSuccess(response));
            // any action in componentdidMount for every yser data private.
            if(tokenDevice){
                dispatch(updateTokenDevise(tokenDevice))
            }       
            dispatch(fetchMessages())
            dispatch(fetchMyTrips())
            dispatch(fetchMyDeliveries())
            history.push('/home')
        })
        .catch(error => {
            if (error.text) {
                error.text().then(errorMessage => {
                    let errorText = JSON.parse(errorMessage).message;
                    var errMess = errorText === "Authentication Failed: EMAIL_PASSWORD_WRONG" ? " Email ou Mot de passe incorrect" : "votre compte n'est pas encore confirmé.\n veuillez vérifier votre boîte de réception ";
                    dispatch(loginFailure(errMess))
                    dispatch(errorAlert(errMess));
                })
            } else {
                dispatch(loginFailure(error));
                dispatch(errorAlert(error.toString()));
            }
        })

}

export const loginRequest = () => ({
    type: userActionType.LOGIN_REQUEST
});

/* a action*/
export const loginFailure = (errmess) => ({
    type: userActionType.LOGIN_FAILURE,
    payload: errmess
});

/* a action*/
export const loginSuccess = (user) => ({
    type: userActionType.LOGIN_SUCCESS,
    payload: user
});



export const authenticateWithFacebook = (name, email,tokenDevice) => (dispatch) => {
    dispatch(loginRequest());
    var fullName = name.split(" ")
    const user = {
        email: email,
        firstName: fullName[1],
        lastName: fullName[0]
    }
    return fetch(baseUrl + 'loginwithfacebook', {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (!response.ok) {
                /*server response with error like  500*/
                throw response;
            }

            else {
                return response.json();
            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        //.then(response => response.json())
        .then(response => {
            localStorage.setItem('user', JSON.stringify(response));
            dispatch(loginSuccess(response));
            // any action in componentdidMount for every yser data private.
            if(tokenDevice){
                dispatch(updateTokenDevise(tokenDevice));
            }  
            dispatch(fetchMessages())
            dispatch(fetchMyTrips())
            dispatch(fetchMyDeliveries())
            history.push('/home')
        })
        .catch(error => {
            var errMess = "eudeliv don't accept facebook account without email: try to register with email !";
            dispatch(loginFailure(errMess))
            dispatch(errorAlert(errMess));
        })

}

export const authenticateWithGoogle = (name, email,tokenDevice) => (dispatch) => {
    dispatch(loginRequest());
    var fullName = name.split(" ")
    const user = {
        email: email,
        firstName: fullName[1],
        lastName: fullName[0]
    }
    return fetch(baseUrl + 'loginwithfacebook', {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (!response.ok) {
                /*server response with error like  500*/
                throw response;
            }

            else {
                return response.json();
            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        //.then(response => response.json())
        .then(response => {
            localStorage.setItem('user', JSON.stringify(response));
            dispatch(loginSuccess(response));
            // any action in componentdidMount for every yser data private.
            if(tokenDevice){
                dispatch(updateTokenDevise(tokenDevice))
            }  
            dispatch(fetchMessages())
            dispatch(fetchMyTrips())
            dispatch(fetchMyDeliveries())
            history.push('/home')
        })
        .catch(error => {
            var errMess = "please register with your email: (eudeliv app policy)";
            dispatch(loginFailure(errMess))
            dispatch(errorAlert(errMess));
        })

}

export const logout = () => (dispatch) => {
    localStorage.removeItem('user');
    dispatch(logoutSuccess());
    history.push('/login');

}
export const logoutSuccess = () => ({
    type: userActionType.LOGOUT
});

export const sendEmailResetPassword = (email) => (dispatch) => {
    return fetch(`${baseUrl}resetpws?email=${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response =>
            dispatch(sendEmailResetPasswordSuccess())
        )

        .catch(error => {
            dispatch(errorAlert("INTERNAL ERROR"));
        })


}
export const sendEmailResetPasswordSuccess = () => ({
    type: userActionType.SEND_EMAIL_RESETPASSWORD
});

export const resetPassword = (token, password) => (dispatch) => {
    dispatch(loginRequest());
    const newPassword = {
        token: token,
        password: password,
    }
    return fetch(`${baseUrl}resetpws`, {
        method: 'POST',
        body: JSON.stringify(newPassword),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => {
            dispatch(ResetPasswordSuccess())
            history.push('/login');
            dispatch(successAlert("votre mot de passe a été réinitialiser avec succès"));
        })

        .catch(error => {
            dispatch(errorAlert("INTERNAL ERROR"));
        })


}
export const ResetPasswordSuccess = () => ({
    type: userActionType.UPDATE_PASSWORD
});





export const register = (user) => (dispatch) => {
    dispatch(registerRequest());
    return fetch(baseUrl + 'signup', {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.text())
        .then(response => {
            var alertText = response === "user created" ? "Félicitation  : Votre compte est créé" : "Ce compte existe déjà. Connectez-vous ou verifiez votre boite mail pour valider votre compte ";
            dispatch(registerSuccess(response));
            history.push('/login')
            dispatch(successAlert(alertText));


        })
        .catch(error => {
            dispatch(registerFailure(error));
            dispatch(errorAlert(error.toString()));
        })

}


export const registerRequest = () => ({
    type: userActionType.REGISTER_REQUEST
});

/* a action*/
export const registerFailure = (errmess) => ({
    type: userActionType.REGISTER_FAILURE,
    payload: errmess
});

/* a action*/
export const registerSuccess = (response) => ({
    type: userActionType.REGISTER_SUCCESS,
    payload: response
});


export const updateUser = (user) => (dispatch) => {
    dispatch(updateUserRequest());
    const newUser = {

        id: currentUser().id,
        lastName: user.lastName,
        firstName: user.firstName,
        birthDate: user.birthDate,
        numberMobile: user.numberMobile,
    }
    return fetch(baseUrl + '/service/updateuser', {
        method: 'POST',
        body: JSON.stringify(newUser),
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(response => {

            
            var userLocalStorage = localStorage.getItem('user');

            // If no existing data, create an array
            // Otherwise, convert the localStorage string to an array
            userLocalStorage = userLocalStorage ? JSON.parse(userLocalStorage) : {};

            // Add new data to localStorage Array
            userLocalStorage['user'] = response;

            // Save back to localStorage
            localStorage.setItem('user', JSON.stringify(userLocalStorage));
            dispatch(updateUserSuccess(userLocalStorage));
            dispatch(successAlert("votre profil  a été mise à jour avec succès"));
        })
        .catch(error => {
            dispatch(updateUserFailure(error));
            dispatch(errorAlert("INTERNAL ERROR"));
        })

}

export const updateUserRequest = () => ({
    type: userActionType.UPDATEUSER_REQUEST
});


/* a action*/
export const updateUserSuccess = (response) => ({
    type: userActionType.UPDATEUSER_SUCCESS,
    payload: response
});

/* a action*/
export const updateUserFailure = (errmess) => ({
    type: userActionType.UPDATEUSER_FAILURE,
    payload: errmess
});


export const updateTokenDevise = (token_devise_token) => (dispatch) => {
    if(localStorage.getItem('user')){
    const newUser = {
        id: currentUser().id,
        token_devise_token: token_devise_token,
    }
    return fetch(baseUrl + '/service/updatetokendevise', {
        method: 'POST',
        body: JSON.stringify(newUser),
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => {

        console.log("updatesuccess token devise")
        })
        .catch(error => {
            console.log("error while update devisetoken")
        })

    }

}