import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Loading } from '../common/LoadingComponent';
import { Fade, Stagger } from 'react-animation-components';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


class MyTrips extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        
        const renderMeanTrasport = (meanOfTransport) => {
            switch (meanOfTransport) {
                case 'Avion':
                    return <span className="fa fa-plane"></span>;
                case 'Voiture':
                    return <span className="fa fa-car"></span>;
                case 'Train':
                    return <span className="fa fa-train"></span>;
                case 'Bus':
                    return <span className="fa fa-bus" ></span>;
                default:
                    return '';
            }
        }

        const RenderTripItem = ({ trip }) => {
            const departure = trip.departure.split('--');


            const Dest1 = () => {
                const dest = trip.destination.split('--');
                if (dest[1]){
                    return (
                        <CardText ><i className="fa fa-arrow-down"></i> <strong>{dest[1]}</strong></CardText>
                    )
                }else  
                    return (
                        <div></div>
                    )
            }
            const Dest2 = () =>  {
                const dest = trip.destination.split('--');
                if (dest[2]){
                    return (
                        <CardText ><i className="fa fa-arrow-down"></i> <strong>{dest[2]}</strong></CardText>
                    )
                }else  
                    return (
                        <div></div>
                    )
            }
            const Dest3 = () => {
                const dest = trip.destination.split('--');
                if (dest[3]){
                    return (
                        <CardText ><i className="fa fa-arrow-down"></i> <strong>{dest[3]}</strong></CardText>
                    )
                }else  
                    return (
                        <div></div>
                    )
            }
            const Dest4 = () => {
                const dest = trip.destination.split('--');
                if (dest[4]){
                    return (
                        <CardText><h6><i className="fa fa-map-marker"></i> <strong>{dest[4]}</strong></h6></CardText>
                    )
                }else  
                    return (
                        <div></div>
                    )
            }
            return (
                <>
        
                <div key={trip.id} className="mt-3">
                    <Card >
                        <Link to={`/mytrips/${trip.id}`} className="inheritLinkColor">
                            <Row>
                                <Col xs="8">
                                    <CardBody>
                                        <CardTitle heading>
                                            Le {new Intl.DateTimeFormat('fr-FR', { weekday: 'short', month: 'short', day: '2-digit' }).format(trip.dateDeparture)}
                                        &nbsp;&nbsp;  &nbsp;&nbsp; {renderMeanTrasport(trip.meansOfTransport)}
                                        </CardTitle>
                                        <CardText > <h6><i className="fa fa-map-marker"></i> <strong>{departure[1]}</strong></h6></CardText>
                                        <Dest1 destination= {trip.destination}/>
                                        <Dest2 destination= {trip.destination}/>
                                        <Dest3 destination= {trip.destination}/>
                                        <Dest4 destination= {trip.destination}/>
                                    </CardBody>
                                </Col>
                                <Col xs="4">
                                    <CardBody>
                                        <h5 className="text-right">
                                            {trip.price} €
                                    </h5>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Link>
                    </Card>
                </div>
                </>
            );
        }


        const myTrips = this.props.myTrips.myTrips.map((trip) => {
            return (
                <Fade in>
                    <RenderTripItem trip={trip} />
                </Fade>
            );
        });

        if (this.props.myTrips.isLoadingTrips) {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <Loading />
                    </Col>
                </Row>
            );
        }
        else if (this.props.myTrips.errMess) {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <h4>{this.props.myTrips.errMess}</h4> 
                    </Col>
                </Row>
            );
        }
        else if (Array.isArray(this.props.myTrips.myTrips) && this.props.myTrips.myTrips.length)
            return (
                <div class="container">
                    <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }} >
                            <h3 className="text-center mt-3">Mes trajets</h3>
                            <br />
                            <Stagger in>
                                {myTrips}
                                <br/><br/><br/><br/>
                            </Stagger>
                        </Col>
                    </Row>
                </div>
            );
        else
            return (
                <div class="container">
                <Row>
                    <Col className="text-center mt-5" sm="12" md={{ size: 6, offset: 3 }} >
                        <br /><br />
                        <h2>vous n'avez aucun trajet !! </h2>
                        <br />
                        <Link to="/addtrip">
                            <Button color="primary" size="lg">Ajouter un trajet</Button>
                        </Link>
                    </Col>
                </Row>
                </div>

            );
    }
}


export default MyTrips;


