
import {actionType as userActionType} from '../actions/actionType/user.actionTypes';


const initialState =  {
    isRegister:false,
    errMess : null,
    responseRegister : null
   } 
export const registration = (state = initialState, action) => {
    switch (action.type) {

        case userActionType.REGISTER_REQUEST:
            return {...state,isRegister : true, errMess:null, responseRegister:null}

        case userActionType.REGISTER_SUCCESS:
            return {...state,isRegister : false, errMess:null, responseRegister:action.payload}

        case userActionType.REGISTER_FAILURE:
            return {...state,isRegister : false, errMess:action.payload, responseRegister:null}

        default:
          return state;
      }
};