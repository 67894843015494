import { actionType as mytripsActionType } from './actionType/myTrips.actionTypes';
import { baseUrl } from '../shared/baseUrl';
import { authHeader } from '../helpers/auth-header';
import { currentUser } from '../helpers/currentUser';
import { error as errorAlert, success as successAlert } from './alert.actionCreator';
import { history } from '../helpers/history';




export const fetchMyTrips = () => (dispatch) => {
    if(localStorage.getItem('user')){
    dispatch(myTripsRequest());
    return fetch(`${baseUrl}/service/mytrips?code=${currentUser().id}`, {
        method: 'GET',
        headers: { 
            ...authHeader(), 
            'Content-Type': 'application/json' },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error('Error ' + response.status + ':' + response.error);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(response => dispatch(myTripsSuccess(response)))
        .catch(error =>  dispatch(myTripsFailure(error.message)))

    }

}


export const deleteTrip = (idAnnouncement) => (dispatch) => {
    return fetch(`${baseUrl}/service/deteleannouncement?idUser=${currentUser().id}&idAnnouncement=${idAnnouncement}`, {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
    })
        .then(response => {
            if (response.ok) {
                return response;
            }
            /*server response with error like  500*/
            else {
                var error = new Error(response.status);
                error.response = response;
                throw error;

            }
        },
            /*server doesn't response*/
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => {
            history.push('/mytrips')
            dispatch(fetchMyTrips());
            dispatch(successAlert("votre annonce a été supprimer avec succès"));
        })
        .catch(error => {
            dispatch(errorAlert("INTERNAL ERROR"));
        })

}

export const myTripsRequest = () => ({
    type: mytripsActionType.MYTRIPS_REQUEST
});

/* a action*/
export const myTripsFailure = (errmess) => ({
    type: mytripsActionType.MYTRIPS_FAILURE,
    payload: errmess
});

/* a action*/
export const myTripsSuccess = (myTrips) => ({
    type: mytripsActionType.MYTRIPS_SUCCESS,
    payload: myTrips
});

export const postTrip = (trip) => ({

    type: mytripsActionType.ADD_TRIP,
    payload: trip
});




