import React,{useEffect} from 'react';
import { Button, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle,Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FadeTransform } from 'react-animation-components';



function ConditionUtilisation(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
       <div>
             <Jumbotron>
                 <div className="container">
                     <div className="row row-header">
                         <div className="col-12 col-sm-6">
                             <h1>Conditions d’utilisation</h1>
                         </div>
                        
                     </div>
                 </div>
             </Jumbotron>
             
             <div className="container row row-content">
              <div className="col-12 col-sm-9 offset-sm-1">
             
1- Les données personnelles sont les informations qui permettent d’identifier un utilisateur. Ces données sont régies par la protection des données ( RGPD). eudeliv s’engage à la protection de vos données à caractère personnel.
 <br/><br/>

 2-	Les données nécessaires à votre inscription (nom, prénom, adresse e-mail, téléphone) sans ses informations eudeliv vous sera inaccessible.
<br/><br/>


3- Des données de votre profil peuvent être visualisés, par les autres utilisateurs du site, pour la prise du contact.
<br/><br/>

4- Vos données personnelles sont très importantes. eudeliv accorde une grande importance au respect de la vie privée et s’engage à ne pas commercialiser ses données.

<br/><br/>
5- Vos données peuvent être utilisées lors de nos prospections commerciales ( newsletters ). Vous pourrez à tout moment marquer votre désaccord en envoyant un mail sur contact@eudeliv.com.

<br/><br/>
6- Vous avez le droit d’être informé sur la manière dont est utilisée vos données personnelles et le droit de faire une demande auprès eudeliv.
<br/><br/>

7-Vous disposez du droit de rectification et d’effacement de vos données personnelles. Sur simple demande, vos données seront effacées ou mises à jour par nos soins.
<br/><br/>

8-Vous avez le droit de vous opposer aux traitements de vos données personnelles. Pour une efficacité, nous nous réservons le droit de modifier en partie ou en totalité les termes et conditions liés à eudeliv.

<br/><br/>
9- eudeliv vous encourage à être vigilant et à contrôler les colis, produits ou petits paquets que l’on vous remet.
<br/><br/>
10- eudeliv n’étant qu’une plateforme d’annonces et de mise en relation, seuls l’envoyeur et les preneurs sont responsables du colis envoyé ou reçu. Ils définiront ensemble le coût du service s’il y a lieu. eudeliv se désengage donc et ne sera tenu ni de près, ni de loin, responsable quant à la forme et au contenu du colis envoyé ou reçu.
              </div>
          </div>
        </div>
    );
}

export default ConditionUtilisation;