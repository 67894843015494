import {actionType as messagesActionType} from '../actions/actionType/message.actionTypes';

const initialState =  {
    isLoadingMessages:false,
    errMess : null,
    messages : []
   } 
export const messages = (state = initialState, action) => {
    switch (action.type) {

        case messagesActionType.MESSAGES_REQUEST:
            return {...state,isLoadingMessages : true, errMess:null, messages:[] }

        case messagesActionType.MESSAGES_SUCCESS:
            return {...state,isLoadingMessages : false, errMess:null, messages:action.payload }

        case messagesActionType.MESSAGES_FAILURE:
            return {...state,isLoadingMessages : false, errMess:action.payload, messages:[]}

        case messagesActionType.SEND_MESSAGE:
                return {...state,isLoadingMessages : false, errMess:null, messages:state.messages.concat(action.payload)  }  

        default:
          return state;
      }
};


