import {actionType as addTripActionType} from '../actions/actionType/addTrip.actionTypes';


const initialState =  {
    isAddingTrip:false,
    errMess : null,
    responseAddTrip : {}
   } 
export const addTrip = (state = initialState, action) => {
    switch (action.type) {

        case addTripActionType.ADDTRIP_REQUEST:
            return {...state,isAddingTrip : true, errMess:null, responseAddTrip:{}}

        case addTripActionType.ADDTRIP_SUCCESS:
            return {...state,isAddingTrip : false, errMess:null, responseAddTrip:action.payload}

        case addTripActionType.ADDTRIP_FAILURE:
            return {...state,isAddingTrip : false, errMess:action.payload, responseAddTrip:{}}

        default:
          return state;
      }
};