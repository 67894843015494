import React,{useEffect} from 'react';
import { Button, Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle,Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FadeTransform } from 'react-animation-components';



function History(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
       <div>
             <Jumbotron>
                 <div className="container">
                     <div className="row row-header">
                         <div className="col-12 col-sm-6">
                             <h1>Notre histoire</h1>
                         </div>
                        
                     </div>
                 </div>
             </Jumbotron>
             
             <div className="container row row-content">
              <div className="col-12">
              <h3>Probléme/Solution</h3>
              <br/>
              </div>
              <div className="col-12 col-sm-9 offset-sm-1">
              Partant un jour en vacance, chez un ami depuis Paris à Nice, 
              au retour en oubliant les clés de l’appartement à Nice, 
              il est donc impossible de rentrer chez moi, l’appartement ou j’étais à Nice est proche de l’aéroport, 
              mon ami est parti à l’aéroport et il a confié mes clés à un voyageur qui fait le trajet de Nice à Paris 
              contre une petite somme d’argent pour réduire le cout de leur voyage, Ainsi dans deux heures 
              j’ai récupéré les clés en toutes sécurité et rapidité. Les personnes ayant un cas d’urgence comme 
              celui-là peuvent rentrer en contact, avec les gens qui font le trajet contre une rémunération
               ou non sur eudeliv.
              </div>
              
              <div className="col-12">
              <br/>
              <h3>Comment tout a commencé</h3>
              <br/>
              </div>
              <div className="col-12 col-sm-9 offset-sm-1">
               Tout à commencer quand KIDOUN Brahim a besoin d’un document urgent pour accomplir une démarche administrative en 
               France, Ce document se trouve au Maroc dans la ville Oujda, pour envoyer un document avec les solutions ordinaires
               qui existe dans le marché depuis le Maroc vers la France ça prend des semaines pour que le courrier arrive à 
               destination, ce qu’est impossible pour ce cas de KIDOUN Brahim qui il avait besoin du document dans la journée même.
               Heureusement que dans ce jour la y’avait une personne dans la famille qui fait le trajet de Oujda à Paris 
               (Maroc vers France) par avion, et son père a confié ce document à cette personne.
               <br/><br/>
               Apres cette expérience, par curiosité Brahim veut avoir une idée sur le nombre de trajet depuis le Maroc à paris 
               juste par avion sans les bus, voitures…, pour le but de confier le même document à une personne dans le cas ou 
               y’avait pas cette personne de sa famille qu’il a fait ce trajet, Brahim a trouvé qu’il y’avait des centaines de 
               voyages, ainsi des centaines de milliers de voyageurs.
               Il avait des centaines de milliers voyageurs pour lui confier le document juste par avion.
               <br/><br/>
               Brahim a compris que la solution magique pour envoyer des documents urgents ou d’autre colis (document, médicament, 
               cadeaux, bagage…), il a bien place dans le bagage des voyageurs qui font le trajet et pas dans les solutions 
               ordinaires qui coute chère et arrive toujours tardive.
               <br/><br/>
               Pendant les semaines qui ont suivi, Brahim ne put fermer l’œil. Il devait certainement exister une solution centralisant 
               les voyageurs disponibles qui font le trajet. Après une recherche sur internet, il a trouvé une liste de forums répertoriant 
               les offres de personnes qu’ils ont une demande de livraison urgente et des gens qui font des trajets et veulent transporter 
               des colis. Le nombre d’annonce était minime, 
               et le tout était si désorganisé qu’il était impossible de trouver quelqu’un pour lui confier un colis.
               <br/><br/>
               Pendant ses études au Maroc et en France, et des missions au sein des grandes entreprise et start-up qu’il a fait
               un peu partout dans la France (Montpellier, Nice, Paris, Lyon), et il avait adopté la mentalité des startups, il 
               comprit donc immédiatement que cette idée avait un potentiel énorme. Il comprit que de nombreuses 
               personnes pourraient bénéficier d’une solution de transport de colis entre particuliers abordable, pratique et conviviale.
               <br/><br/>
               <h4>Serait-il possible que le bagage vide des voyageurs soient à l’origine d’un nouveau réseau de livraison?</h4>
               <br/><br/>
               A l’époque où Brahim était un jeune étudiant au Maroc et en France, son père toujours lui envoyer des colis 
               (argent, documents, nourriture, médicaments …) avec des gens qui font le trajet ou il a fait ses études 
               (Casablanca, Lyon, Paris), tout en pensant qu’il s’agissait de l’exemple magique d’une excellente idée 
               mal exploitée. Il était impossible d’envoyer un colis à n’importe quel moment, il faut toujours attendre 
               une personne de son entourage qui fait le trajet à destination de sa ville. Il y avait souvent un temps 
               d’attente gênant. Brahim, qui se sentait mal à l’aise de confier des colis gratuitement à des personnes 
               au lieu de l’envoyer avec les solutions ordinaires de livraison en payant une somme d’argent non négligeable,
                tous les voyageurs n’étaient pas prêt  à faire ce cadeau, et tous les voyageurs  n’ont toujours pas de bagage 
                libre, donc beaucoup de gens peuvent avoir de bagage libre  et prêt à transporter 
               des colis contre une petite somme d’argent pour réduire le cout du trajet. Cette idée devenu une logique gagnant-gagnant.
               <br/><br/>    
               Après avoir travaillé un peu plus sur cette idée, Brahim a démissionné et il a commencé directement le  développement
                de l’idée puisque il a acquis des compétences en développements suites à différents missions dans le domaine 
                informatique. 
                Après huit mois de travail, journée et nuit Brahim a fini la solution qui presente <br/>
                eudeliv ( easy universal delivery ) 

               <br/><br/>

              </div>
          </div>
        </div>
    );
}

export default History;