import React, { Component } from 'react';
import { Control, Form,Errors } from 'react-redux-form';
import { Col, Row } from 'reactstrap';
import { Loading } from '../common/LoadingComponent';
import LocationSearchInput from '../common/LocationSearchCities';
import "react-datepicker/dist/react-datepicker.css"


const required = (val) => val && val.length>2;
class SearchAnnouncements extends Component {

    constructor(props) {
        super(props);
        this.state = {
            departureDate: null
        };

        this.handleSearchAnnouncements = this.handleSearchAnnouncements.bind(this);
        this.handleDepartureDateChange = this.handleDepartureDateChange.bind(this);

    }

    handleDepartureDateChange(data) {
        this.setState({
            departureDate: data
        });
    }

    handleSearchAnnouncements(values) {

        this.props.fetchAnnouncements(values.departure, values.destination, values.dateDeparture);
    }

    render() {
        
        const RenderSearchAnnouncements = ({ isSearchAnnouncements }) => {
            if (isSearchAnnouncements) {
                return (
                    <Row className="text-center">
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Loading />
                        </Col>
                    </Row>

                );
            }
            else {
                return (
                    <div className="container">
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>

                                <Form  validateOn="blur" model="searchTripsForm" onSubmit={(values) => this.handleSearchAnnouncements(values)}
                                    validators={{
                                    departure: { required },
                                    destination : {required}   
                                  }}>
                                    <Row className="text-center">
                                        <Col sm="12" md={{ size: 9, offset: 0 }}>
                                            <br />
                                            <h4>Rechercher un trajet ou une livraison</h4>
                                        </Col>
                                    </Row>
                                    <br />
                                    <br />
                                    <Row className="form-group">
                                        <Col md={10}>
                                            <LocationSearchInput model=".departure" id="departure" name="departure"
                                                placeholder="Départ"
                                                formName="searchTripsForm"
                                            />
                                        </Col>
                                        <Col md={10}>
                                        <Errors model=".departure" messages={{
                                        required: ' Veuillez saisir une ville de départ .'
                                        
                                    }} />
                                        </Col>
                                        
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={10}>

                                            <LocationSearchInput model=".destination" id="destination" name="destination"
                                                placeholder="Destination"
                                                formName="searchTripsForm"
                                            />
                                           
                                        </Col>
                                        <Col md={10}>
                                        <Errors model=".destination" messages={{
                                        required: ' Veuillez saisir une ville de destination .'
                                        
                                    }} />
                                        </Col>
                                        
                                    </Row>

                                    {/* <Row className="form-group text-center">
                                        <Col sm="12" md={{ size: 9, offset: 0 }} >
                                            <Control type="date" model=".dateDeparture" id="dateDeparture" name="dateDeparture"
                                                className="form-control"
                                                component={DatePicker}
                                                onChange={this.handleDepartureDateChange}
                                                minDate={new Date()}
                                                selected={this.state.departureDate}
                                                value={this.state.departureDate}
                                                dateFormat=" dd-MM-yyyy"
                                                placeholderText="date de départ"
                                                locale={fr}
                                            />
                                        </Col>
                                    </Row> */}
                                    <br /><br />
                                    <Row className="form-group text-center">
                                        <Col sm="12" md={{ size: 9, offset: 0 }}>
                                            <Control.button className="btn btn-primary" model="searchTripsForm" type="submit" color="primary">
                                                Rechercher
                                            </Control.button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>

                    </div>
                );
            }
        }

        return (
            <div>
                <br />
                <RenderSearchAnnouncements isSearchAnnouncements={this.props.isSearchAnnouncements} />
            </div>
        );

    }

}


export default SearchAnnouncements;

