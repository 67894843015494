import {createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';






export const ConfigureStore = () => {


    const persistedState =loadFromLocalStorage();
    const store = createStore(

        rootReducer,
        persistedState,
        applyMiddleware(thunk,logger)
    );

    function saveToLocaStorage (state){
        try{
            const serializedState = JSON.stringify(state)
            localStorage.setItem('state',serializedState)
        } catch(e){
            console.log(e)
        }
    }
    
    function loadFromLocalStorage(){
        try{
            const serializedState = localStorage.getItem('state')
            if(serializedState === null) return undefined
            return JSON.parse(serializedState)
        } catch(e){
            console.log(e)
            return undefined
        }
    }

    store.subscribe(()=>saveToLocaStorage(store.getState()))
    return store;
}