import {actionType as searchTripsActionType} from '../actions/actionType/searchTrips.actionTypes';


const initialState =  {
    isSearchAnnouncements:false,
    errMess : null,
    responseSearchAnnouncements : []
   } 
export const searchTrips = (state = initialState, action) => {
    switch (action.type) {

        case searchTripsActionType.SEARCHTRIPS_REQUEST:
            return {...state,isSearchAnnouncements : true, errMess:null, responseSearchAnnouncements:[]}

        case searchTripsActionType.SEARCHTRIPS_SUCCESS:
            return {...state,isSearchAnnouncements : false, errMess:null, responseSearchAnnouncements:action.payload}

        case searchTripsActionType.SEARCHTRIPS_FAILURE:
            return {...state,isSearchAnnouncements : false, errMess:action.payload, responseSearchAnnouncements:[]}

        default:
          return state;
      }
};