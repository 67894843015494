import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Loading } from '../common/LoadingComponent';
import { Fade, Stagger } from 'react-animation-components';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


class MyDeliveries extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        const RenderDeliveryItem = ({ delivery }) => {
            const departure = delivery.departure.split('--');
            const destination = delivery.destination.split('--');

            return (
                <div key={delivery.id} className="mt-3">
                    <Card >
                        <Link to={`/mydeliveries/${delivery.id}`} className="inheritLinkColor">
                            <Row>
                                <Col xs="8">
                                    <CardBody>
                                        <CardTitle heading>
                                            Le {new Intl.DateTimeFormat('fr-FR', { weekday: 'short', month: 'short', day: '2-digit' }).format(delivery.dateDeparture)}                                       
                                        </CardTitle>
                                        <CardText > <h6><i className="fa fa-map-marker"></i> {departure[0]}</h6></CardText>
                                        <CardText > <h6><i className="fa fa-map-marker"></i> {destination[0]}</h6></CardText>
                                    </CardBody>
                                </Col>
                                <Col xs="4">
                                    <CardBody>
                                        <h5 className="text-right">
                                            {delivery.price} €
                                    </h5>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Link>
                    </Card>
                </div>
            );
        }


        const myDeliveries = this.props.myDeliveries.myDeliveries.map((delivery) => {
            return (
                <Fade in>
                    <RenderDeliveryItem delivery={delivery} />
                </Fade>
            );
        });

        if (this.props.myDeliveries.isLoadingMyDeliveries) {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <Loading />
                    </Col>
                </Row>
            );
        }
        else if (this.props.myDeliveries.errMess) {
            return (
                <Row className="text-center">
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                         <h4>{this.props.myDeliveries.errMess}</h4> 
                    </Col>
                </Row>
            );
        }
        else if (Array.isArray(this.props.myDeliveries.myDeliveries) && this.props.myDeliveries.myDeliveries.length)
            return (
                <div className="container">
                    <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }} >
                            <h3 className="text-center mt-3">Mes demandes livraison</h3>
                            <br />
                            <Stagger in>
                                {myDeliveries}
                                <br/><br/><br/><br/>
                            </Stagger>
                        </Col>
                    </Row>
                </div>
            );
        else
            return (
                <div class="container">
                <Row>
                    <Col className="text-center mt-5" sm="12" md={{ size: 6, offset: 3 }} >
                        <br /><br />
                        <h2>Vous n'avez aucune demande livraison !! </h2>
                        <br />
                        <Link to="/adddelivery">
                            <Button color="primary" size="lg">Envoyer un colis</Button>
                        </Link>
                    </Col>
                </Row>
              </div>

            );
    }
}


export default MyDeliveries;


