import {  alertActionType } from './actionType/alert.actionTypes';

export const success = (message) => ({
    type: alertActionType.SUCCESS, message
});

/* a action*/
export const error = (message) => ({
    type: alertActionType.ERROR, message
});

/* a action*/
export const clear = () => ({
    type: alertActionType.CLEAR 
});