import React, { useState } from 'react';
import { Button, Col, Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Link } from 'react-router-dom';
import { Loading } from '../common/LoadingComponent';



const required = (val) => val && val.length;
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);
//const passwordsMatch = (vals) => vals.password === vals.confirmpassword;



function DemandResetPassword(props) {

    const [isSentEmail, setIsSentEmail] = useState(false);
    function handleDemandResetPassword(values) {
        props.sendEmailResetPassword(values.email);
        setIsSentEmail(true);
        
    }

    if (isSentEmail) {
        return (
            <div className="container">
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }} >
                        <h3 className="text-center mt-15">Réinitialisez votre mot de passe</h3>
                    </Col>
                </Row>
                <br />
                <Row className="Login">
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <Card className="text-center">
                            <CardBody>
                                <CardTitle>
                                    Nous vous envoyons un mail pour réinitialiser votre mot de passe.
                                    Si vous ne recevez aucun mail,
                                    regardez dans votre dossier spam ou
                                    assurez-vous que cette adresse e-mail est enregistrée sur eudeliv
                                </CardTitle>
                            </CardBody>
                        </Card>
                        <br /><br /><br />
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div class="container">
            <div className="container col-12 col-md-6 text-center">
                <br />
                <h3> Réinitialisez votre mot de passe</h3>
                <h6>Veuillez saisir votre adresse e-mail ci-dessous. Nous rechercherons votre compte et vous enverrons un e-mail de réinitialisation de mot de passe</h6>
            </div>
            <div className="Login text-center">
                <LocalForm  onSubmit={(values) => handleDemandResetPassword(values)}
                    validators={{
                        email: { required, validEmail}                       
                      }}>
                    <Row className="form-group">
                        <Col>
                            <Control.text model=".email" id="email" name="email" innerRef={(input) => this.email = input}
                                placeholder="E-mail"
                                className="form-control"
                                validators={{
                                    required, validEmail
                                }} />
                            <Errors
                                className="errors"
                                model=".email"
                                show="touched"
                                messages={{
                                    required: ' Veuillez saisir votre email.',
                                    validEmail: ' Vérifiez votre email, son format est invalide.'
                                }}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Button type="submit" value="submit" color="primary"><strong>Envoyer la réinitialisation de mot de passe</strong></Button>
                </LocalForm>
                <br />
            </div>
        </div>



    );
}

export default DemandResetPassword;